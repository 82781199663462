export default {
  methods: {
    reqGetHotelRoomTypeFeatureAll(callback, errorCallback) {
      const url = `/rest/hotel/${this.uid}/room/${this.$route.params.roomTypeId}/feature`;
      this.baseReqGet(url, callback, errorCallback);
    },

    reqPostHotelRoomTypeFeature(body, callback, errorCallback) {
      const url = `/rest/hotel/${this.uid}/room/${this.$route.params.roomTypeId}/feature`;
      this.baseReqPost(url, body, callback, errorCallback);
    },
  },
};
