export default {
  methods: {
    reqGetHotelListAll(callback, errorCallback) {
      const url = `/rest/list`;
      this.baseReqGet(url, callback, errorCallback);
    },

    reqGetHotelList(callback, errorCallback) {
      const url = `/rest/list/${this.$route.params.facilityListId}`;
      this.baseReqGet(url, callback, errorCallback);
    },

    reqPostHotelList(body, callback, errorCallback) {
      const url = `/rest/list`;
      this.baseReqPost(url, body, callback, errorCallback);
    },

    reqPutHotelList(body, callback, errorCallback) {
      const url = `/rest/list/${this.$route.params.facilityListId}`;
      this.baseReqPut(url, body, callback, errorCallback);
    },

    reqPutHotelListStatus(id, body, callback, errorCallback) {
      const url = `/rest/list/${id}/status`;
      this.baseReqPut(url, body, callback, errorCallback);
    },

    reqPutHotelListOrder(body, callback, errorCallback) {
      const url = `/rest/list/order`;
      this.baseReqPut(url, body, callback, errorCallback);
    },

    reqDeleteHotelList(callback, errorCallback) {
      const url = `/rest/list/${this.$route.params.facilityListId}`;
      this.baseReqDelete(url, callback, errorCallback);
    },
  },
};
