export default {
  methods: {
    reqGetHotelAll(callback, errorCallback) {
      const url = `/rest/hotel`;
      this.baseReqGet(url, callback, errorCallback);
    },

    reqGetHotel(callback, errorCallback) {
      const url = `/rest/hotel/${this.uid}`;
      this.baseReqGet(url, callback, errorCallback);
    },

    reqGetSearchHotel(requestUrl, callback, errorCallback) {
      const url = requestUrl;
      this.baseReqGet(url, callback, errorCallback);
    },

    reqPostHotel(body, callback, errorCallback) {
      const url = `/rest/hotel`;
      this.baseReqPost(url, body, callback, errorCallback);
    },

    reqPutHotel(body, callback, errorCallback) {
      const url = `/rest/hotel/${this.uid}`;
      this.baseReqPut(url, body, callback, errorCallback);
    },

    reqPutHotelStatus(id, body, callback, errorCallback) {
      const url = `/rest/hotel/${id}/status`;
      this.baseReqPut(url, body, callback, errorCallback);
    },

    reqPutHotelOrder(body, callback, errorCallback) {
      const url = `/rest/hotel/order`;
      this.baseReqPut(url, body, callback, errorCallback);
    },

    reqDeleteHotel(callback, errorCallback) {
      const url = `/rest/hotel/${this.uid}`;
      this.baseReqDelete(url, callback, errorCallback);
    },
  },
};
