// TODO: API未使用なら削除する

export default {
  methods: {
    reqGetCountryAll(callback, errorCallback) {
      const url = `/rest/country`;
      this.baseReqGet(url, callback, errorCallback);
    },

    reqGetCountry(callback, errorCallback) {
      const url = `/rest/country/${this.$route.params.countryId}`;
      this.baseReqGet(url, callback, errorCallback);
    },

    reqPostCountry(body, callback, errorCallback) {
      const url = `/rest/country`;
      this.baseReqPost(url, body, callback, errorCallback);
    },

    reqPutCountry(body, callback, errorCallback) {
      const url = `/rest/country/${this.$route.params.countryId}`;
      this.baseReqPut(url, body, callback, errorCallback);
    },

    reqPutCountryStatus(id, body, callback, errorCallback) {
      const url = `/rest/country/${id}/status`;
      this.baseReqPut(url, body, callback, errorCallback);
    },

    reqPutCountryOrder(body, callback, errorCallback) {
      const url = `/rest/country/order`;
      this.baseReqPut(url, body, callback, errorCallback);
    },

    reqDeleteCountry(callback, errorCallback) {
      const url = `/rest/country/${this.$route.params.countryId}`;
      this.baseReqDelete(url, callback, errorCallback);
    },
  },
};
