export default {
  methods: {
    reqGetHotelPlanPropertyAll(callback, errorCallback) {
      const url = `/rest/hotel/${this.uid}/plan/${this.$route.params.planId}/property`;
      this.baseReqGet(url, callback, errorCallback);
    },

    reqGetHotelPlanProperty(callback, errorCallback) {
      const url = `/rest/hotel/${this.uid}/plan/${this.$route.params.planId}/property/${this.$route.params.propertyId}`;
      this.baseReqGet(url, callback, errorCallback);
    },

    reqPostHotelPlanProperty(body, callback, errorCallback) {
      const url = `/rest/hotel/${this.uid}/plan/${this.$route.params.planId}/property`;
      this.baseReqPost(url, body, callback, errorCallback);
    },

    reqPutHotelPlanProperty(body, callback, errorCallback) {
      const url = `/rest/hotel/${this.uid}/plan/${this.$route.params.planId}/property/${this.$route.params.propertyId}`;
      this.baseReqPut(url, body, callback, errorCallback);
    },

    reqPutHotelPlanPropertyStatus(id, body, callback, errorCallback) {
      const url = `/rest/hotel/${this.uid}/plan/${this.$route.params.planId}/property/${id}/status`;
      this.baseReqPut(url, body, callback, errorCallback);
    },

    reqPutHotelPlanPropertyOrder(body, callback, errorCallback) {
      const url = `/rest/hotel/${this.uid}/plan/${this.$route.params.planId}/property/order`;
      this.baseReqPut(url, body, callback, errorCallback);
    },

    reqDeleteHotelPlanProperty(callback, errorCallback) {
      const url = `/rest/hotel/${this.uid}/plan/${this.$route.params.planId}/property/${this.$route.params.propertyId}`;
      this.baseReqDelete(url, callback, errorCallback);
    },
  },
};
