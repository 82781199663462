export default {
  methods: {
    reqGetHotelPlanSiteAll(callback, errorCallback) {
      const url = `/rest/hotel/${this.uid}/plan/${this.$route.params.planId}/site`;
      this.baseReqGet(url, callback, errorCallback);
    },

    reqGetHotelPlanSite(callback, errorCallback) {
      const url = `/rest/hotel/${this.uid}/plan/${this.$route.params.planId}/site/${this.$route.params.siteId}`;
      this.baseReqGet(url, callback, errorCallback);
    },

    reqPostHotelPlanSite(body, callback, errorCallback) {
      const url = `/rest/hotel/${this.uid}/plan/${this.$route.params.planId}/site`;
      this.baseReqPost(url, body, callback, errorCallback);
    },

    reqPutHotelPlanSite(body, callback, errorCallback) {
      const url = `/rest/hotel/${this.uid}/plan/${this.$route.params.planId}/site/${this.$route.params.siteId}`;
      this.baseReqPut(url, body, callback, errorCallback);
    },

    reqPutHotelPlanSiteStatus(id, body, callback, errorCallback) {
      const url = `/rest/hotel/${this.uid}/plan/${this.$route.params.planId}/site/${id}/status`;
      this.baseReqPut(url, body, callback, errorCallback);
    },

    reqPutHotelPlanSiteOrder(body, callback, errorCallback) {
      const url = `/rest/hotel/${this.uid}/plan/${this.$route.params.planId}/site/order`;
      this.baseReqPut(url, body, callback, errorCallback);
    },

    reqDeleteHotelPlanSite(callback, errorCallback) {
      const url = `/rest/hotel/${this.uid}/plan/${this.$route.params.planId}/site/${this.$route.params.siteId}`;
      this.baseReqDelete(url, callback, errorCallback);
    },
  },
};
