import Vue from 'vue'
import App from './App.vue'
import config from './config'
import axios from "axios";
import router from './router'
import store from './store'
import i18n from './i18n';
import CoreuiVue from '@coreui/vue'
import { iconsSet as icons } from './assets/icons/icons.js'
import VueKeycloak from '@dsb-norge/vue-keycloak-js'
import VueFilterDateFormat from 'vue-filter-date-format';
import Vuelidate from 'vuelidate'
import Vuex from 'vuex'
Vue.use(Vuex)
import VueMeta from 'vue-meta'
Vue.use(VueMeta)

// imageloader  

import VueLazyload from 'vue-lazyload';

const loadimage = require('./assets/images/spinner.gif')
const errorimage = require('./assets/images/no-image.png')

Vue.use(VueLazyload, {
  preLoad: 1.3,
  error: errorimage,
  loading: loadimage,
  attempt: 1
})
// https://saintplay.github.io/vue-swatches/guide/
// import Swatches from 'vue-swatches'
import '@/filter/index' //import filter file

import Draggable from 'vuedraggable'
Vue.component('Draggable', Draggable)


import Calendar from 'v-calendar/lib/components/calendar.umd'
import DatePicker from 'v-calendar/lib/components/date-picker.umd'

// Register components in your 'main.js'
Vue.component('Calendar', Calendar)
Vue.component('DatePicker', DatePicker)

import 'es6-promise/auto'

// start custom components

import PreLoading from './components/Loading';
Vue.component('PreLoading', PreLoading);
import ImgView from './components/ImgView';
Vue.component('ImgView', ImgView);
import ImageUploader from './components/ImageUploader';
Vue.component('ImageUploader', ImageUploader);
import ImageSlider from './components/ImageSlider';
Vue.component('ImageSlider', ImageSlider);
import SocialShare from './components/SocialShare';
Vue.component('SocialShare', SocialShare);
import ScrollTop from './components/ScrollTop';
Vue.component('ScrollTop', ScrollTop);
import WordLimit from './components/WordLimit';
Vue.component('WordLimit', WordLimit);
import LoginTimeOut from './components/LoginTimeOut';
Vue.component('LoginTimeOut', LoginTimeOut);
import SuccessModal from "./components/SuccessModal";
Vue.component('SuccessModal', SuccessModal);
import ErrorModal from "./components/ErrorModal";
Vue.component('ErrorModal', ErrorModal);
import DeleteConfirmModal from "./components/DeleteConfirmModal";
Vue.component('DeleteConfirmModal', DeleteConfirmModal);

// end custom components

import VueSocialSharing from 'vue-social-sharing'
Vue.use(VueSocialSharing);

import * as VueGoogleMaps from 'vue2-google-maps'
Vue.use(VueGoogleMaps, {
  load: {
    key: config.GOOGLE_MAPS_API_KEY,
    libraries: 'places',
    // FIXME: 多言語対応
    region: 'JP',
    language: 'ja'
    // libraries: 'places', // This is required if you use the Autocomplete plugin
  }
})
import Geocoder from "@pderas/vue2-geocoder";
Vue.use(Geocoder, {
  // FIXME: 多言語対応
  defaultCountryCode: 'JP', // e.g. 'CA'
  defaultLanguage: 'ja', // e.g. 'en'
  defaultMode: 'address', // or 'lat-lng'
  googleMapsApiKey: config.GOOGLE_MAPS_API_KEY
});

Vue.config.productionTip = false

Vue.use(CoreuiVue)
Vue.use(VueFilterDateFormat)
Vue.use(Vuelidate)
Vue.prototype.$log = console.log.bind(console)

Vue.use(VueKeycloak, {
  init: {
    // Use 'login-required' to always require authentication
    // If using 'login-required', there is no need for the router guards in router.js
    onLoad: 'check-sso'
  },
  config: {
    //This is master version
    //Setup Keycloak server authentication url
    url: config.KEYCLOAK_SERVER_AUTH_URL,
    clientId: 'hotaka-spring-boot-app',
    realm: 'Liberty',
    // logoutRedirectUri: this.$router.push(),
  },




  onReady: (keycloak) => {
    const pageLoad =
      new Vue({
        data: function () {
          return { loading: false };
        },
        router,
        store,
        icons,
        keycloak,
        i18n,
        render: h => h(App)
      }).$mount('#app')
    /** 
     * Make spinner by router data
     */
    router.beforeEach((to, from, next) => {
      pageLoad.loading = true
      next()
    })

    router.afterEach(() => {
      pageLoad.loading = false
    })

  },
  onInitError: error => {
    console.log('erreur init :', error)
  },
})

/** 
 * Make spinner by axios response
 */

//**Axios request interceptor */
axios.interceptors.request.use(function (config) {
  // NOTE: 開発時は localhost:8080 にリクエストする
  // NOTE: ただし、会員システム等の他サービスへのリクエストはdomain設定不要
  const domain = document.domain === "localhost" ? "http://localhost:8080" : "";
  config.url = config.url.charAt(0) === "/" ? `${domain}${config.url}` : config.url;

  store.commit("load") //vuex mutation set loading state to true
  return config;
},
  function (error) {
    return Promise.reject(error);
  });

//**Axios response  interceptor */
axios.interceptors.response.use(function (config) {
  store.commit("notload") //vuex mutation set loading state to false
  return config;
},
  function (error) {
    store.commit("notload") //vuex mutation set loading state to false
    console.log("response error")
    return Promise.reject(error);
  });

/**
 *  Assign switch language to local language
 */
i18n.locale = store.state.currentLanguage

//#region mixins
// import * as data from "@/mixins/property";
import utils from "@/mixins/utils";
import requestBase from "@/mixins/request/base";
import request from "@/mixins/request";
import imageLoader from "@/mixins/imageLoader.js";


// Vue.mixin(data)
Vue.mixin(utils)
Vue.mixin(requestBase)
Vue.mixin(request)
Vue.mixin(imageLoader)
//#endregion mixins