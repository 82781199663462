<template>

  <!-- #region Error -->
  <CModal
    color="danger"
    closeOnBackdrop
    :centered="true"
    :show.sync="errorModal"
  >
    <p>{{ errorMessage }}</p>
    <template #header>
      <h4 class="modal-title">{{ $t("common.error") }}</h4>
    </template>
    <template #footer>
      <CButton
        @click="$emit('close', false)"
        color="secondary"
      >
        <i class="icon cil-x"></i> {{ $t("common.close") }}
      </CButton>
    </template>
  </CModal>
  <!-- #endregion Error -->

</template>

<script>

export default {

  name: "ErrorModal",

  props: {

    errorModal: {
      type: Boolean,
      required: true,
      default: false,
    },

    errorMessage: {
      type: String,
      required: true,
    }

  },

};
</script>
