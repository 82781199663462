<template>
  <span>
    <transition name="fade">
    <span v-html="fullContent"></span>
    </transition>
    <span
      class="read-more"
      @click="readMore()"
      v-if="ifMore"
    > ... {{$t('common.readMore')}} &#10095;</span>
    <span
      class="read-less"
      @click="readLess()"
      v-else
    > &#10094; {{$t('common.readLess')}}</span>
  </span>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
    },
    length: {
      length: Number,
    },
  },
  data() {
    return {
      preragraph: "",
      Llength: 1,
      ifMore: false,
      ifLess: false,
    };
  },
  computed: {
    fullContent() {
      if (this.text.length > this.Llength) {
        return this.text.substring(0, this.Llength) ;
      } else {
        return this.text;
      }
    },
  },

  methods: {
    defText() {
      this.Llength = this.length;
      if (this.text.length > this.length) {
        this.ifMore = true;
      }
    },
    readMore() {
      this.Llength = this.text.length;
      this.ifMore = false;
    },
    readLess() {
      this.Llength = this.length;
      this.ifMore = true;
    },
  },

  beforeMount() {
    this.defText();
  },
};
</script>
<style scoped>
.read-more,
.read-less {
  color: #39f;
  text-decoration: underline;
  margin-left: 4px;
  cursor: pointer;
}
</style>

