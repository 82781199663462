export default {
  methods: {
    reqGetHotelRoomTypeAll(callback, errorCallback) {
      const url = `/rest/hotel/${this.uid}/room`;
      this.baseReqGet(url, callback, errorCallback);
    },

    reqGetHotelRoomType(callback, errorCallback) {
      const url = `/rest/hotel/${this.uid}/room/${this.$route.params.roomTypeId}`;
      this.baseReqGet(url, callback, errorCallback);
    },

    reqPostHotelRoomType(body, callback, errorCallback) {
      const url = `/rest/hotel/${this.uid}/room`;
      this.baseReqPost(url, body, callback, errorCallback);
    },

    reqPutHotelRoomType(body, callback, errorCallback) {
      const url = `/rest/hotel/${this.uid}/room/${this.$route.params.roomTypeId}`;
      this.baseReqPut(url, body, callback, errorCallback);
    },

    reqPutHotelRoomTypeStatus(id, body, callback, errorCallback) {
      const url = `/rest/hotel/${this.uid}/room/${id}/status`;
      this.baseReqPut(url, body, callback, errorCallback);
    },

    reqPutHotelRoomTypeOrder(body, callback, errorCallback) {
      const url = `/rest/hotel/${this.uid}/room/order`;
      this.baseReqPut(url, body, callback, errorCallback);
    },

    reqDeleteHotelRoomType(callback, errorCallback) {
      const url = `/rest/hotel/${this.uid}/room/${this.$route.params.roomTypeId}`;
      this.baseReqDelete(url, callback, errorCallback);
    },
  },
};
