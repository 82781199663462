<template>
  <div class="cocial-share-button">
    <ul>
      <li
        v-for="share in sosButton"
        :key="share.name"
      >
        <ShareNetwork
          :network="share.name"
          :url="pageUrl"
          title="oHOw"
        >
          <i :class="share.icon+' text-'+share.color"></i>
        </ShareNetwork>
        <!-- title="Say hi to Vite! A brand new, extremely fast development setup for Vue."
          description="This week, I’d like to introduce you to 'Vite', which means 'Fast'. It’s a brand new development setup created by Evan You."
          quote="The hot reload is so fast it\'s near instant. - Evan You"
          hashtags="vuejs,vite" -->
      </li>
      <li>
        <a href="#">
          <i class="cib-instagram text-danger"></i>
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "SocialShare",
  data() {
    return {
      sosButton: [
        {
          name: "facebook",
          icon: "cib-facebook",
          color: "primary",
        },
        {
          name: "twitter",
          icon: "cib-twitter",
          color: "info",
        },
        // {
        //   name: "instagram",
        //   icon: "cib-instagram",
        //   color: "warning",
        // },
        {
          name: "line",
          icon: "cib-line",
          color: "success",
        },
      ],
      pageUrl: "",
    };
  },
  created: function () {
    this.pageUrl = window.location.host + this.$router.currentRoute.path;
  },
  methods: {},
};
</script>

<style lang="scss">
// .cib-mail:before {
//   content: "\2709";
//   font-weight: bold;
//   font-size: 1.2em;
// }
.cocial-share-button {
  ul {
    text-align: right;
    li {
      display: inline-block;
      margin: 0 0 0 1rem;
      font-size: 1.5rem;
      color: #ccc;
      a:hover {
        border: none !important;
        text-decoration: none;
      }
    }
  }
}
</style>