export default {
  methods: {
    reqGetAmenityAll(callback, errorCallback) {
      const url = `/rest/amenity`;
      this.baseReqGet(url, callback, errorCallback);
    },

    reqGetAmenity(callback, errorCallback) {
      const url = `/rest/amenity/${this.$route.params.amenityId}`;
      this.baseReqGet(url, callback, errorCallback);
    },

    reqPostAmenity(body, callback, errorCallback) {
      const url = `/rest/amenity`;
      this.baseReqPost(url, body, callback, errorCallback);
    },

    reqPutAmenity(body, callback, errorCallback) {
      const url = `/rest/amenity/${this.$route.params.amenityId}`;
      this.baseReqPut(url, body, callback, errorCallback);
    },

    reqPutAmenityStatus(id, body, callback, errorCallback) {
      const url = `/rest/amenity/${id}/status`;
      this.baseReqPut(url, body, callback, errorCallback);
    },

    reqPutAmenityOrder(body, callback, errorCallback) {
      const url = `/rest/amenity/order`;
      this.baseReqPut(url, body, callback, errorCallback);
    },

    reqDeleteAmenity(callback, errorCallback) {
      const url = `/rest/amenity/${this.$route.params.amenityId}`;
      this.baseReqDelete(url, callback, errorCallback);
    },
  },
};
