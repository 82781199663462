// TODO: パラメータの取り方あっている？

export default {
  methods: {
    reqGetHotelDetailAll(callback, errorCallback) {
      const url = `/rest/hotel/${this.uid}/detail`;
      this.baseReqGet(url, callback, errorCallback);
    },

    reqGetHotelDetailByPropertyId(propertyId, callback, errorCallback) {
      const url = `/rest/hotel/${this.uid}/detail/${propertyId}`;
      this.baseReqGet(url, callback, errorCallback);
    },

    reqGetHotelDetail(callback, errorCallback) {
      const url = `/rest/hotel/${this.uid}/detail/${this.$route.params.propertyId}`;
      this.baseReqGet(url, callback, errorCallback);
    },

    reqPostHotelDetail(body, callback, errorCallback) {
      const url = `/rest/hotel/${this.uid}/detail`;
      this.baseReqPost(url, body, callback, errorCallback);
    },

    reqPutHotelDetail(body, callback, errorCallback) {
      const url = `/rest/hotel/${this.uid}/detail/${this.$route.params.propertyId}`;
      this.baseReqPut(url, body, callback, errorCallback);
    },

    reqPutHotelDetailByPropertyId(propertyId, body, callback, errorCallback) {
      const url = `/rest/hotel/${this.uid}/detail/${propertyId}`;
      this.baseReqPut(url, body, callback, errorCallback);
    },

    reqDeleteHotelDetail(callback, errorCallback) {
      const url = `/rest/hotel/${this.uid}/detail/${this.$route.params.propertyId}/item/${this.$route.params.detailId}`;
      this.baseReqDelete(url, callback, errorCallback);
    },

    reqDeleteHotelDetailByPropertyItemId(propertyId, itemId, callback, errorCallback) {
      const url = `/rest/hotel/${this.uid}/detail/${propertyId}/item/${itemId}`;
      this.baseReqDelete(url, callback, errorCallback);
    },
  },
};
