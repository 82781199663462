import {
  cibFacebook,
  cibTwitter,
  cibLinkedin,
  cibFlickr,
  cibTumblr,
  cibXing,
  cibGithub,
  cibStackOverflow,
  cibYoutube,
  cibDribbble,
  cibInstagram,
  cibPinterest,
  cibVk,
  cibYahoo,
  cibBehance,
  cibReddit,
  cibVimeo,
  cibCcMastercard,
  cibCcVisa,
  cibStripe,
  cibPaypal,
  cibGooglePay,
  cibCcAmex,
  cibGmail
} from '@coreui/icons'
import {
  cifJp,
  cifUs,
  cifBr,
  cifIn,
  cifFr,
  cifEs,
  cifCn,
  cifPl
} from '@coreui/icons'
import {
  cilArrowRight,
  cilBan,
  cilBasket,
  cilBell,
  cilCalculator,
  cilCalendar,
  cilCloudDownload,
  cilChartPie,
  cilCheck,
  cilChevronBottom,
  cilChevronTop,
  cilCheckCircle,
  cilCommentSquare,
  cilCursor,
  cilDrop,
  cilDollar,
  cilEnvelopeClosed,
  cilEnvelopeOpen,
  cilEuro,
  cilGlobeAlt,
  cilGrid,
  cilFile,
  cilJustifyCenter,
  cilLaptop,
  cilLayers,
  cilLibraryAdd,
  cilLightbulb,
  cilList,
  cilLocationPin,
  cilLockLocked,
  cilMagnifyingGlass,
  cilMoon,
  cilOptions,
  cilPencil,
  cilPeople,
  cilPlus,
  cilPuzzle,
  cilReload,
  cilRoom,
  cilSettings,
  cilShieldAlt,
  cilSpeech,
  cilSpeedometer,
  cilStar,
  cilTask,
  cilUser,
  cilUserFemale,
  cilUserFollow,
  cilXCircle,
  cilTags,
  cilBed,
  cilInbox,
  cilYen,
  cilSwapHorizontal,
  cilHome,
  cilEqualizer,
  cilCog,
  cilMap,
  cilMoney,
  cilHotTub,
  cilImage,
  cilListRich,
  cilBuilding,
  cilRestaurant,
  cilHouse,
  cilNotes,
  cilContact,
  cilNewspaper,
  cilLink,
  cilBellExclamation,
  cilHistory,
  cilElevator,
  cilCommentBubble,
  cilChatBubble,
  cilThumbUp,
  cilExposure,
  cilAirplaneMode,
  cilFlightTakeoff,
  cilNoteAdd,
  cilWarning,
  cilSmilePlus,
} from '@coreui/icons'
import {
  cilAccountLogout
} from '@coreui/icons'
import { logo } from './logo'

export const iconsSet = Object.assign(
  {},
  { logo },
  {
    cilArrowRight,
    cilBan,
    cilBasket,
    cilBell,
    cilCalculator,
    cilCalendar,
    cilCloudDownload,
    cilChartPie,
    cilCheck,
    cilChevronBottom,
    cilChevronTop,
    cilCheckCircle,
    cilCommentSquare,
    cilCursor,
    cilDrop,
    cilDollar,
    cilEnvelopeClosed,
    cilEnvelopeOpen,
    cilEuro,
    cilGlobeAlt,
    cilGrid,
    cilFile,
    cilJustifyCenter,
    cilLaptop,
    cilLayers,
    cilLibraryAdd,
    cilLightbulb,
    cilList,
    cilLocationPin,
    cilLockLocked,
    cilMagnifyingGlass,
    cilMoon,
    cilOptions,
    cilPencil,
    cilPeople,
    cilPlus,
    cilPuzzle,
    cilRoom,
    cilReload,
    cilSettings,
    cilShieldAlt,
    cilSpeech,
    cilSpeedometer,
    cilStar,
    cilTask,
    cilUser,
    cilUserFemale,
    cilUserFollow,
    cilXCircle,
    cilTags,
    cilBed,
    cilInbox,
    cilYen,
    cilSwapHorizontal,
    cilHome,
    cilEqualizer,
    cilCog,
    cilMap,
    cilMoney,
    cilHotTub,
    cilImage,
    cilListRich,
    cilBuilding,
    cilRestaurant,
    cilHouse,
    cilNotes,
    cilContact,
    cilNewspaper,
    cilLink,
    cilBellExclamation,
    cilHistory,
    cilElevator,
    cilCommentBubble,
    cilChatBubble,
    cilThumbUp,
    cilExposure,
    cilAirplaneMode,
    cilFlightTakeoff,
    cilNoteAdd,
    cilWarning,
    cilSmilePlus,
  },
  {
    cilAccountLogout,
  },
  {
    cifJp,
    cifUs,
    cifBr,
    cifIn,
    cifFr,
    cifEs,
    cifCn,
    cifPl
  },
  {
    cibFacebook,
    cibTwitter,
    cibLinkedin,
    cibFlickr,
    cibTumblr,
    cibXing,
    cibGithub,
    cibStackOverflow,
    cibYoutube,
    cibDribbble,
    cibInstagram,
    cibPinterest,
    cibVk,
    cibYahoo,
    cibBehance,
    cibReddit,
    cibVimeo,
    cibCcMastercard,
    cibCcVisa,
    cibStripe,
    cibPaypal,
    cibGooglePay,
    cibCcAmex,
    cibGmail
  }
)
