export default {
  methods: {
    reqGetRoomTypeAll(callback, errorCallback) {
      const url = `/rest/room`;
      this.baseReqGet(url, callback, errorCallback);
    },

    reqGetRoomType(callback, errorCallback) {
      const url = `/rest/room/${this.$route.params.roomTypeId}`;
      this.baseReqGet(url, callback, errorCallback);
    },

    reqPostRoomType(body, callback, errorCallback) {
      const url = `/rest/room`;
      this.baseReqPost(url, body, callback, errorCallback);
    },

    reqPutRoomType(body, callback, errorCallback) {
      const url = `/rest/room/${this.$route.params.roomTypeId}`;
      this.baseReqPut(url, body, callback, errorCallback);
    },

    reqPutRoomTypeStatus(id, body, callback, errorCallback) {
      const url = `/rest/room/${id}/status`;
      this.baseReqPut(url, body, callback, errorCallback);
    },

    reqPutRoomTypeOrder(body, callback, errorCallback) {
      const url = `/rest/room/order`;
      this.baseReqPut(url, body, callback, errorCallback);
    },

    reqDeleteRoomType(callback, errorCallback) {
      const url = `/rest/room/${this.$route.params.roomTypeId}`;
      this.baseReqDelete(url, callback, errorCallback);
    },
  },
};
