export default {
  methods: {
    reqGetWebError(callback, errorCallback) {
      const url = `/rest/error`;
      this.baseReqGet(url, callback, errorCallback);
    },

    reqPutWebError(body, callback, errorCallback) {
      const url = `/rest/error`;
      this.baseReqPut(url, body, callback, errorCallback);
    },
  },
};
