export default {
  methods: {
    reqGetHotelRoomTypePropertyAll(callback, errorCallback) {
      const url = `/rest/hotel/${this.uid}/property`;
      this.baseReqGet(url, callback, errorCallback);
    },

    reqGetHotelRoomTypeProperty(callback, errorCallback) {
      const url = `/rest/hotel/${this.uid}/property/${this.$route.params.propertyId}`;
      this.baseReqGet(url, callback, errorCallback);
    },

    reqPostHotelRoomTypeProperty(body, callback, errorCallback) {
      const url = `/rest/hotel/${this.uid}/property`;
      this.baseReqPost(url, body, callback, errorCallback);
    },

    reqPutHotelRoomTypeProperty(body, callback, errorCallback) {
      const url = `/rest/hotel/${this.uid}/property/${this.$route.params.propertyId}`;
      this.baseReqPut(url, body, callback, errorCallback);
    },

    reqPutHotelRoomTypePropertyStatus(id, body, callback, errorCallback) {
      const url = `/rest/hotel/${this.uid}/property/${id}/status`;
      this.baseReqPut(url, body, callback, errorCallback);
    },

    reqPutHotelRoomTypePropertyOrder(body, callback, errorCallback) {
      const url = `/rest/hotel/${this.uid}/property/order`;
      this.baseReqPut(url, body, callback, errorCallback);
    },

    reqDeleteHotelRoomTypeProperty(callback, errorCallback) {
      const url = `/rest/hotel/${this.uid}/property/${this.$route.params.propertyId}`;
      this.baseReqDelete(url, callback, errorCallback);
    },
  },
};
