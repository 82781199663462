<template>

  <!-- #region Success -->
  <CModal
    color="success"
    :closeOnBackdrop="false"
    :centered="true"
    :show.sync="successModal"
  >
    {{ $t("commonMessage.updateSuccessText") }}
    <template #header> {{ $t("commonMessage.updateSuccessHeader") }} </template>
    <template #footer>
      <CButton
        @click="$emit('close', false)"
        color="secondary"
      >
        <i class="icon cil-x"></i> {{ $t("common.close") }}
      </CButton>
    </template>
  </CModal>
  <!-- #endregion Success -->

</template>

<script>

export default {
  name: "SuccessModal",

  props: {
    
    car:{
      type: Array
    },
    successModal: {
      type: Boolean,
      required: true,
      default: false,
    }

  },

};
</script>
