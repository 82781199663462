import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index'
Vue.use(VueRouter)

//#region ----- Component -----
const Home = () => import('@/views/visitor/Home.vue')
const Login = () => import('@/views/visitor/Login.vue')
const About = () => import('@/views/visitor/About.vue')

const Logout = () => import('@/views/visitor/Logout')
const NotFound = () => import('@/views/visitor/404.vue')
const TestPage = () => import('@/views/visitor/Test.vue')
const TestPage2 = () => import('@/views/visitor/Test2.vue')

const FacilityDetail = () => import('@/views/visitor/Facility.vue')
const FacilityList = () => import('@/views/anonymous/FacilityList.vue')

const ADMIN_COMPONENT = {
  THE_CONTAINER: () => import('@/containers/admin/TheContainer'),

  DASHBOARD: () => import('@/views/admin/Dashboard/Dashboard.vue'),

  FACILITY_LIST: () => import('@/views/admin/Facility/List.vue'),
  FACILITY_CREATE: () => import('@/views/admin/Facility/Create.vue'),
  FACILITY_USER_RELATION: () => import('@/views/admin/Facility/UserRelation.vue'),

  FACILITY_LIST_LIST: () => import('@/views/admin/FacilityList/List.vue'),
  FACILITY_LIST_CREATE: () => import('@/views/admin/FacilityList/Create.vue'),
  FACILITY_LIST_EDIT: () => import('@/views/admin/FacilityList/Edit.vue'),

  OPERATOR_LIST: () => import('@/views/admin/Operator/List.vue'),
  OPERATOR_FACILITY_RELATION: () => import('@/views/admin/Operator/FacilityRelation.vue'),

  STAFF_LIST: () => import('@/views/admin/Staff/List.vue'),
  STAFF_EDIT: () => import('@/views/admin/Staff/Edit.vue'),

  ARTICLE_LINK_LIST: () => import('@/views/admin/ArticleLink/List.vue'),
  ARTICLE_LINK_CREATE: () => import('@/views/admin/ArticleLink/Create.vue'),
  ARTICLE_LINK_EDIT: () => import('@/views/admin/ArticleLink/Edit.vue'),

  SITE_LIST: () => import('@/views/admin/Site/List.vue'),
  SITE_CREATE: () => import('@/views/admin/Site/Create.vue'),
  SITE_EDIT: () => import('@/views/admin/Site/Edit.vue'),

  TAG_LIST: () => import('@/views/admin/Tag/List.vue'),
  TAG_CREATE: () => import('@/views/admin/Tag/Create.vue'),
  TAG_EDIT: () => import('@/views/admin/Tag/Edit.vue'),

  AREA_LIST: () => import('@/views/admin/Area/List.vue'),
  AREA_CREATE: () => import('@/views/admin/Area/Create.vue'),
  AREA_EDIT: () => import('@/views/admin/Area/Edit.vue'),

  FEATURE_LIST: () => import('@/views/admin/Feature/List.vue'),
  FEATURE_CREATE: () => import('@/views/admin/Feature/Create.vue'),
  FEATURE_EDIT: () => import('@/views/admin/Feature/Edit.vue'),

  MEDIA_KIND_LIST: () => import('@/views/admin/MediaKind/List.vue'),
  MEDIA_KIND_CREATE: () => import('@/views/admin/MediaKind/Create.vue'),
  MEDIA_KIND_EDIT: () => import('@/views/admin/MediaKind/Edit.vue'),

  CALENDAR_EDIT: () => import('@/views/admin/Calendar/Edit.vue'),

}

const STAFF_COMPONENT = {
  THE_CONTAINER: () => import('@/containers/staff/TheContainer'),

  DASHBOARD: () => import('@/views/staff/Dashboard/Dashboard.vue'),

  // NOTE: adminのcomponentを参照
  FACILITY_LIST: ADMIN_COMPONENT.FACILITY_LIST,
}

const OPERATOR_COMPONENT = {
  THE_CONTAINER: () => import('@/containers/operator/TheContainer'),

  DASHBOARD: () => import('@/views/operator/Dashboard/Dashboard.vue'),

  FACILITY_SWITCH: () => import('@/views/operator/Facility/SwitchList.vue'),
  FACILITY_EDIT: () => import('@/views/operator/Facility/Edit.vue'),
  FACILITY_SETTING: () => import('@/views/operator/Facility/Setting.vue'),

  OPERATOR_LIST: () => import('@/views/operator/Operator/List.vue'),

  FACILITY_ARTICLE: () => import('@/views/operator/FacilityArticle/List.vue'),
  FACILITY_TAG_LIST: () => import('@/views/operator/FacilityTag/List.vue'),

  IMAGE_LIST: () => import('@/views/operator/Image/List.vue'),

  // TODO: IMAGE_CATEGORY削除予定
  IMAGE_CATEGORY_LIST: () => import('@/views/operator/ImageCategory/List.vue'),
  IMAGE_CATEGORY_CREATE: () => import('@/views/operator/ImageCategory/Create.vue'),
  IMAGE_CATEGORY_EDIT: () => import('@/views/operator/ImageCategory/Edit.vue'),

  PLAN_LIST: () => import('@/views/operator/Plan/List.vue'),
  PLAN_CREATE: () => import('@/views/operator/Plan/Create.vue'),
  PLAN_EDIT: () => import('@/views/operator/Plan/Edit.vue'),

  PLAN_PRICE_LIST: () => import('@/views/operator/PlanPrice/List.vue'),
  PLAN_PRICE_EDIT: () => import('@/views/operator/PlanPrice/Edit.vue'),

  PLAN_CATEGORY_LIST: () => import('@/views/operator/PlanCategory/List.vue'),
  PLAN_CATEGORY_CREATE: () => import('@/views/operator/PlanCategory/Create.vue'),
  PLAN_CATEGORY_EDIT: () => import('@/views/operator/PlanCategory/Edit.vue'),

  ROOM_TYPE_LIST: () => import('@/views/operator/RoomType/List.vue'),
  ROOM_TYPE_CREATE: () => import('@/views/operator/RoomType/Create.vue'),
  ROOM_TYPE_EDIT: () => import('@/views/operator/RoomType/Edit.vue'),

  ROOM_INVENTORY_EDIT: () => import('@/views/operator/RoomInventory/Edit.vue'),

  PRICE_TYPE_CALENDAR_EDIT: () => import('@/views/operator/PriceCalendar/Edit.vue'),

  CANCEL_POLICY_LIST: () => import('@/views/operator/CancelPolicy/List.vue'),
  CANCEL_POLICY_CREATE: () => import('@/views/operator/CancelPolicy/Create.vue'),
  CANCEL_POLICY_EDIT: () => import('@/views/operator/CancelPolicy/Edit.vue'),

  OPTION_LIST: () => import('@/views/operator/Option/List.vue'),
  OPTION_CREATE: () => import('@/views/operator/Option/Create.vue'),
  OPTION_EDIT: () => import('@/views/operator/Option/Edit.vue'),

  QUESTION_LIST: () => import('@/views/operator/Question/List.vue'),
  QUESTION_CREATE: () => import('@/views/operator/Question/Create.vue'),
  QUESTION_EDIT: () => import('@/views/operator/Question/Edit.vue'),

  OPTION_INVENTORY_EDIT: () => import('@/views/operator/OptionInventory/Edit.vue'),

  AGE_EDIT: () => import('@/views/operator/Age/Edit.vue'),

  RESERVATION_LIST: () => import('@/views/operator/Reservation/List.vue'),
  RESERVATION_EDIT: () => import('@/views/operator/Reservation/Edit.vue'),

  CONTACT_LIST: () => import('@/views/operator/Contact/List.vue'),
  CONTACT_EDIT: () => import('@/views/operator/Contact/Edit.vue'),

  REVIEW_LIST: () => import('@/views/operator/Review/List.vue'),
  REVIEW_EDIT: () => import('@/views/operator/Review/Edit.vue'),
}

const MEMBER_COMPONENT = {
  THE_CONTAINER: () => import('@/containers/member/TheContainer'),

  DASHBOARD_DASHBOARD: () => import('@/views/member/Dashboard/Dashboard.vue'),

  RESERVATIONHISTORY_LIST: () => import('@/views/member/ReservationHistory/List.vue'),

  WAITING_LIST: () => import('@/views/member/Waiting/List.vue'),
  WAITING_EDIT: () => import('@/views/member/Waiting/Edit.vue'),

  COUPON_LIST: () => import('@/views/member/Coupon/List.vue'),

  PROFILE_EDIT: () => import('@/views/member/Profile/Edit.vue'),

  MAIL_MAGAZINE_LIST: () => import('@/views/member/MailMagazine/List.vue')
}

//#endregion ----- Component -----

const ROLE = {
  ADMIN: "yado_operator_admin",
  OPERATOR: "yado_operator",
  MEMBER: "liberty_member",
  STAFF: "yado_operator_staff",
}

let router = new VueRouter({
  mode: 'history', // https://router.vuejs.org/api/#mode
  linkActiveClass: 'active',
  scrollBehavior: () => ({
    y: 0
  }),
  routes: configRoutes()
})

function configRoutes() {
  return [

    //#region ----- Admin -----
    {
      path: '/admin',
      name: "",
      redirect: '/admin/dashboard',
      component: ADMIN_COMPONENT.THE_CONTAINER,
      children: [

        //#region プラン
        {
          path: 'plan',
          name: 'PlanAdmin',
          redirect: 'plan/list',
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [{
            path: 'list',
            name: 'PlanListAdmin',
            component: OPERATOR_COMPONENT.PLAN_LIST,
            meta: {
              requiresAuth: true,
              uniqueId: true,
              roles: [ROLE.ADMIN, ROLE.STAFF, ROLE.OPERATOR]
            }
          },
          {
            path: 'create',
            name: 'PlanCreateAdmin',
            component: OPERATOR_COMPONENT.PLAN_CREATE,
            meta: {
              requiresAuth: true,
              uniqueId: true,
              roles: [ROLE.ADMIN, ROLE.STAFF, ROLE.OPERATOR]
            }
          },
          {
            path: ':planId/edit',
            name: 'PlanEditAdmin',
            component: OPERATOR_COMPONENT.PLAN_EDIT,
            meta: {
              requiresAuth: true,
              uniqueId: true,
              roles: [ROLE.ADMIN, ROLE.STAFF, ROLE.OPERATOR]
            }
          },
          //#region プランx部屋タイプｘ掲載サイト
          {
            path: ':planId/roomType/:roomTypeId/site',
            name: 'PlanPriceAdmin',
            redirect: ':planId/roomType/:roomTypeId/site/list',
            component: {
              render(c) {
                return c('router-view')
              }
            },
            children: [{
              path: 'list',
              name: 'PlanPriceSiteListAdmin',
              component: OPERATOR_COMPONENT.PLAN_PRICE_LIST,
              meta: {
                requiresAuth: true,
                uniqueId: true,
                roles: [ROLE.ADMIN, ROLE.STAFF, ROLE.OPERATOR]
              }
            },
            {
              path: ':siteId/edit',
              name: 'PlanPriceEditAdmin',
              component: OPERATOR_COMPONENT.PLAN_PRICE_EDIT,
              meta: {
                requiresAuth: true,
                uniqueId: true,
                roles: [ROLE.ADMIN, ROLE.STAFF, ROLE.OPERATOR]
              }
            },
            ]
          },
            //#endregion プランx部屋タイプｘ掲載サイト
          ],
        },
        //#endregion プラン

        //#region ----- プラン管理 -----

        //#region プラン
        {
          path: 'plan',
          name: 'Plan',
          redirect: 'plan/list',
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [{
            path: 'list',
            name: 'PlanList',
            component: OPERATOR_COMPONENT.PLAN_LIST,
            meta: {
              requiresAuth: true,
              uniqueId: true,
              roles: [ROLE.ADMIN, ROLE.STAFF, ROLE.OPERATOR]
            }
          },
          {
            path: 'create',
            name: 'PlanCreate',
            component: OPERATOR_COMPONENT.PLAN_CREATE,
            meta: {
              requiresAuth: true,
              uniqueId: true,
              roles: [ROLE.ADMIN, ROLE.STAFF, ROLE.OPERATOR]
            }
          },
          {
            path: ':planId/edit',
            name: 'PlanEdit',
            component: OPERATOR_COMPONENT.PLAN_EDIT,
            meta: {
              requiresAuth: true,
              uniqueId: true,
              roles: [ROLE.ADMIN, ROLE.STAFF, ROLE.OPERATOR]
            }
          },
          //#region プランx部屋タイプｘ掲載サイト
          {
            path: ':planId/roomType/:roomTypeId/site',
            name: 'PlanPrice',
            redirect: ':planId/roomType/:roomTypeId/site/list',
            component: {
              render(c) {
                return c('router-view')
              }
            },
            children: [{
              path: 'list',
              name: 'PlanPriceSiteList',
              component: OPERATOR_COMPONENT.PLAN_PRICE_LIST,
              meta: {
                requiresAuth: true,
                uniqueId: true,
                roles: [ROLE.ADMIN, ROLE.STAFF, ROLE.OPERATOR]
              }
            },
            {
              path: ':siteId/edit',
              name: 'PlanPriceEdit',
              component: OPERATOR_COMPONENT.PLAN_PRICE_EDIT,
              meta: {
                requiresAuth: true,
                uniqueId: true,
                roles: [ROLE.ADMIN, ROLE.STAFF, ROLE.OPERATOR]
              }
            },
            ]
          },
            //#endregion プランx部屋タイプｘ掲載サイト
          ],
        },
        //#endregion プラン

        //#region プランカテゴリ
        {
          path: 'planCategory',
          name: 'PlanCategoryAdmin',
          redirect: 'planCategory/list',
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [{
            path: 'list',
            name: 'PlanCategoryListAdmin',
            component: OPERATOR_COMPONENT.PLAN_CATEGORY_LIST,
            meta: {
              requiresAuth: true,
              uniqueId: true,
              roles: [ROLE.ADMIN, ROLE.STAFF, ROLE.OPERATOR]
            }
          },
          {
            path: 'create',
            name: 'PlanCategoryCreateAdmin',
            component: OPERATOR_COMPONENT.PLAN_CATEGORY_CREATE,
            meta: {
              requiresAuth: true,
              uniqueId: true,
              roles: [ROLE.ADMIN, ROLE.STAFF, ROLE.OPERATOR]
            }
          },
          {
            path: ':planCategoryId/edit',
            name: 'PlanCategoryEditAdmin',
            component: OPERATOR_COMPONENT.PLAN_CATEGORY_EDIT,
            meta: {
              requiresAuth: true,
              uniqueId: true,
              roles: [ROLE.ADMIN, ROLE.STAFF, ROLE.OPERATOR]
            }
          },
          ],
        },
        //#endregion プランカテゴリ

        //#region 部屋タイプ
        {
          path: 'roomType',
          name: 'RoomType',
          redirect: 'roomType/list',
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [{
            path: 'list',
            name: 'RoomTypeList',
            component: OPERATOR_COMPONENT.ROOM_TYPE_LIST,
            meta: {
              requiresAuth: true,
              uniqueId: true,
              roles: [ROLE.ADMIN, ROLE.STAFF, ROLE.OPERATOR]
            }
          },
          {
            path: 'create',
            name: 'RoomTypeCreate',
            component: OPERATOR_COMPONENT.ROOM_TYPE_CREATE,
            meta: {
              requiresAuth: true,
              uniqueId: true,
              roles: [ROLE.ADMIN, ROLE.STAFF, ROLE.OPERATOR]
            }
          },
          {
            path: ':roomTypeId/edit',
            name: 'RoomTypeEdit',
            component: OPERATOR_COMPONENT.ROOM_TYPE_EDIT,
            meta: {
              requiresAuth: true,
              uniqueId: true,
              roles: [ROLE.ADMIN, ROLE.STAFF, ROLE.OPERATOR]
            }
          }
          ]
        },
        //#endregion 部屋タイプ        

        //#region 料金カレンダー

        {
          path: 'priceCalendar/edit',
          name: 'PriceCalendarEdit',
          component: OPERATOR_COMPONENT.PRICE_TYPE_CALENDAR_EDIT,
          meta: {
            requiresAuth: true,
            uniqueId: true,
            roles: [ROLE.ADMIN, ROLE.STAFF, ROLE.OPERATOR]
          }
        },
        //#endregion 料金カレンダー

        //#region キャンセル規定
        // {
        //   path: 'cancel-provision-list',
        //   name: 'CancelPolicyList',
        //   component: OPERATOR_COMPONENT.CANCEL_POLICY_LIST,
        //   meta: {
        //     requiresAuth: true,
        //     uniqueId: true,
        //     roles: [ROLE.ADMIN, ROLE.STAFF, ROLE.OPERATOR]
        //   }
        // },
        {
          path: 'cancelPolicy',
          name: 'CancelPolicy',
          redirect: 'cancelPolicy/list',
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [{
            path: 'list',
            name: 'CancelPolicyList',
            component: OPERATOR_COMPONENT.CANCEL_POLICY_LIST,
            meta: {
              requiresAuth: true,
              uniqueId: true,
              roles: [ROLE.ADMIN, ROLE.STAFF, ROLE.OPERATOR]
            }
          },
          {
            path: 'create',
            name: 'CancelPolicyCreate',
            component: OPERATOR_COMPONENT.CANCEL_POLICY_CREATE,
            meta: {
              requiresAuth: true,
              uniqueId: true,
              roles: [ROLE.ADMIN, ROLE.STAFF, ROLE.OPERATOR]
            }
          },
          {
            path: ':cancelPolicyId/edit',
            name: 'CancelPolicyEdit',
            component: OPERATOR_COMPONENT.CANCEL_POLICY_EDIT,
            meta: {
              requiresAuth: true,
              uniqueId: true,
              roles: [ROLE.ADMIN, ROLE.STAFF, ROLE.OPERATOR]
            }
          },
          ]
        },

        //#endregion キャンセル規定

        //#region オプション
        {
          path: 'option',
          name: 'Option',
          redirect: 'option/list',
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [{
            path: 'list',
            name: 'OptionList',
            component: OPERATOR_COMPONENT.OPTION_LIST,
            meta: {
              requiresAuth: true,
              uniqueId: true,
              roles: [ROLE.ADMIN, ROLE.STAFF, ROLE.OPERATOR]
            }
          },
          {
            path: 'create',
            name: 'OptionCreate',
            component: OPERATOR_COMPONENT.OPTION_CREATE,
            meta: {
              requiresAuth: true,
              uniqueId: true,
              roles: [ROLE.ADMIN, ROLE.STAFF, ROLE.OPERATOR]
            }
          },
          {
            path: ':optionId/edit',
            name: 'OptionEdit',
            component: OPERATOR_COMPONENT.OPTION_EDIT,
            meta: {
              requiresAuth: true,
              uniqueId: true,
              roles: [ROLE.ADMIN, ROLE.STAFF, ROLE.OPERATOR]
            }
          },
          ]
        },
        //#endregion オプション

        //#region 年齢区分


        {
          path: 'age/edit',
          name: 'AgeEdit',
          component: OPERATOR_COMPONENT.AGE_EDIT,
          meta: {
            requiresAuth: true,
            uniqueId: true,
            roles: [ROLE.ADMIN, ROLE.STAFF, ROLE.OPERATOR]
          }
        },
        //#endregion 年齢区分

        //#endregion ----- プラン管理 -----

        //#region ----- ポータル -----
        //#region ----- ダッシュボード -----
        {
          path: 'dashboard',
          name: 'Dashboard',
          component: ADMIN_COMPONENT.DASHBOARD,
          meta: {
            requiresAuth: true,
            roles: [ROLE.ADMIN]
          }
        },
        //#endregion ----- ダッシュボード -----
        //#endregion ----- ポータル -----


        //#region ----- 宿管理 -----
        //#region ----- 宿 -----
        {
          path: 'facility',
          name: 'Facility',
          redirect: 'facility/list',
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [{
            path: 'list',
            name: 'FacilitySearchList',
            component: ADMIN_COMPONENT.FACILITY_LIST,
            meta: {
              requiresAuth: true,
              roles: [ROLE.ADMIN]

            }
          },
          {
            path: 'create',
            name: 'FacilityCreate',
            component: ADMIN_COMPONENT.FACILITY_CREATE,
            meta: {
              requiresAuth: true,
              roles: [ROLE.ADMIN]
            }
          },
          {
            path: 'userRelation',
            name: 'UserRelation',
            component: ADMIN_COMPONENT.FACILITY_USER_RELATION,
            meta: {
              requiresAuth: true,
              roles: [ROLE.ADMIN]
            }
          },
          ]
        },
        //#endregion ----- 宿 -----
        //#region ----- 宿一覧 -----
        {
          path: 'facilityList',
          name: 'FacilityList',
          redirect: 'facilityList/list',
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [{
            path: 'list',
            name: 'FacilityListList',
            component: ADMIN_COMPONENT.FACILITY_LIST_LIST,
            meta: {
              requiresAuth: true,
              roles: [ROLE.ADMIN]

            }
          },
          {
            path: 'create',
            name: 'FacilityListCreate',
            component: ADMIN_COMPONENT.FACILITY_LIST_CREATE,
            meta: {
              requiresAuth: true,
              roles: [ROLE.ADMIN]
            }
          },
          {
            path: ':facilityListId/edit',
            name: 'FacilityListEdit',
            component: ADMIN_COMPONENT.FACILITY_LIST_EDIT,
            meta: {
              requiresAuth: true,
              roles: [ROLE.ADMIN]
            }
          },
          ]
        },
        //#endregion ----- 宿一覧 -----
        //#region ----- 掲載サイト -----

        {
          path: 'site',
          name: 'Site',
          redirect: 'site/list',
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [{
            path: 'list',
            name: 'SiteList',
            component: ADMIN_COMPONENT.SITE_LIST,
            meta: {
              requiresAuth: true,
              roles: [ROLE.ADMIN]
            }
          },
          {
            path: 'create',
            name: 'SiteCreate',
            component: ADMIN_COMPONENT.SITE_CREATE,
            meta: {
              requiresAuth: true,
              roles: [ROLE.ADMIN]
            }
          },
          {
            path: ':siteId/edit',
            name: 'SiteEdit',
            component: ADMIN_COMPONENT.SITE_EDIT,
            meta: {
              requiresAuth: true,
              roles: [ROLE.ADMIN]
            }
          }
          ]
        },
        //#endregion ----- 掲載サイト -----

        //#endregion ----- 宿管理 -----


        //#region ----- 会員管理 -----
        //#region ----- 宿オペレーター -----
        {
          path: 'operator',
          name: 'Operator',
          redirect: 'operator/list',
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [{
            path: 'list',
            name: 'OperatorList',
            component: ADMIN_COMPONENT.OPERATOR_LIST,
            meta: {
              requiresAuth: true,
              roles: [ROLE.ADMIN]
            }
          },
          {
            path: 'facilityRelation/:userId',
            name: 'FacilityRelation',
            component: ADMIN_COMPONENT.OPERATOR_FACILITY_RELATION,
            meta: {
              requiresAuth: true,
              roles: [ROLE.ADMIN]
            }
          }
          ]
        },
        //#endregion ----- 宿オペレーター -----
        //#region ----- スタッフ -----
        {
          path: 'staff',
          name: 'Staff',
          redirect: 'staff/list',
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [{
            path: 'list',
            name: 'StaffList',
            component: ADMIN_COMPONENT.STAFF_LIST,
            meta: {
              requiresAuth: true,
              roles: [ROLE.ADMIN]
            }
          },
          {
            path: ':staffid/edit',
            name: 'StaffEdit',
            component: ADMIN_COMPONENT.STAFF_EDIT,
            meta: {
              requiresAuth: true,
              roles: [ROLE.ADMIN]
            }
          }
          ]
        },
        //#endregion ----- スタッフ -----
        //#endregion ----- 会員管理 -----

        //#region ----- 記事管理 -----
        //#region ----- 記事リンク -----
        {
          path: 'articleLink',
          name: 'ArticleLink',
          redirect: 'articleLink/list',
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [{
            path: 'list',
            name: 'ArticleLinkList',
            component: ADMIN_COMPONENT.ARTICLE_LINK_LIST,
            meta: {
              requiresAuth: true,
              roles: [ROLE.ADMIN]
            }
          },
          {
            path: 'create',
            name: 'ArticleLinkCreate',
            component: ADMIN_COMPONENT.ARTICLE_LINK_CREATE,
            meta: {
              requiresAuth: true,
              roles: [ROLE.ADMIN]
            }
          },
          {
            path: ':articleLinkId/edit',
            name: 'ArticleLinkEdit',
            component: ADMIN_COMPONENT.ARTICLE_LINK_EDIT,
            meta: {
              requiresAuth: true,
              roles: [ROLE.ADMIN]
            }
          }
          ]
        },
        //#endregion ----- 記事リンク -----
        //#endregion ----- 記事管理 -----

        //#region ----- マスター管理 -----
        //#region ----- 宿特徴 -----
        {
          path: 'feature',
          name: 'Feature',
          redirect: 'feature/list',
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [{
            path: 'list',
            name: 'FeatureList',
            component: ADMIN_COMPONENT.FEATURE_LIST,
            meta: {
              requiresAuth: true,
              roles: [ROLE.ADMIN]
            }
          },
          {
            path: 'create',
            name: 'FeatureCreate',
            component: ADMIN_COMPONENT.FEATURE_CREATE,
            meta: {
              requiresAuth: true,
              roles: [ROLE.ADMIN]
            }
          },
          {
            path: ':featureId/edit',
            name: 'FeatureEdit',
            component: ADMIN_COMPONENT.FEATURE_EDIT,
            meta: {
              requiresAuth: true,
              roles: [ROLE.ADMIN]
            }
          }
          ]
        },
        //#endregion ----- 宿特徴 -----
        //#region ----- タグ -----
        {
          path: 'tag',
          name: 'Tag',
          redirect: 'Tag/list',
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [{
            path: 'list',
            name: 'TagList',
            component: ADMIN_COMPONENT.TAG_LIST,
            meta: {
              requiresAuth: true,
              roles: [ROLE.ADMIN]
            }
          },
          {
            path: 'create',
            name: 'TagCreate',
            component: ADMIN_COMPONENT.TAG_CREATE,
            meta: {
              requiresAuth: true,
              roles: [ROLE.ADMIN]
            }
          },
          {
            path: ':tagId/edit',
            name: 'TagEdit',
            component: ADMIN_COMPONENT.TAG_EDIT,
            meta: {
              requiresAuth: true,
              roles: [ROLE.ADMIN]
            }
          },

          ]
        },
        //#endregion ----- タグ -----
        //#region ----- エリア -----
        {
          path: 'area',
          name: 'Area',
          redirect: 'area/list',
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [{
            path: 'list',
            name: 'AreaList',
            component: ADMIN_COMPONENT.AREA_LIST,
            meta: {
              requiresAuth: true,
              roles: [ROLE.ADMIN]
            }
          },
          {
            path: 'create',
            name: 'AreaCreate',
            component: ADMIN_COMPONENT.AREA_CREATE,
            meta: {
              requiresAuth: true,
              roles: [ROLE.ADMIN]
            }
          },
          {
            path: ':areaId/edit',
            name: 'AreaEdit',
            component: ADMIN_COMPONENT.AREA_EDIT,
            meta: {
              requiresAuth: true,
              roles: [ROLE.ADMIN]
            }
          }
          ]
        },
        //#endregion ----- エリア -----
        //#region ----- メディア分類 -----
        {
          path: 'mediaKind',
          name: 'MediaKind',
          redirect: 'mediaKind/list',
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [{
            path: 'list',
            name: 'MediaKindList',
            component: ADMIN_COMPONENT.MEDIA_KIND_LIST,
            meta: {
              requiresAuth: true,
              roles: [ROLE.ADMIN]
            }
          },
          {
            path: 'create',
            name: 'MediaKindCreate',
            component: ADMIN_COMPONENT.MEDIA_KIND_CREATE,
            meta: {
              requiresAuth: true,
              roles: [ROLE.ADMIN]
            }
          },
          {
            path: ':mediaKindId/edit',
            name: 'MediaKindEdit',
            component: ADMIN_COMPONENT.MEDIA_KIND_EDIT,
            meta: {
              requiresAuth: true,
              roles: [ROLE.ADMIN]
            }
          }
          ]
        },
        //#endregion ----- メディア分類 -----
        //#region ----- カレンダー -----
        {
          path: 'calendar/edit',
          name: 'CalendarEdit',
          component: ADMIN_COMPONENT.CALENDAR_EDIT,
          meta: {
            requiresAuth: true,
            roles: [ROLE.ADMIN]
          }
        },
        //#endregion ----- カレンダー -----
        //#endregion ----- マスター管理 -----
      ],
    },
    //#endregion ----- Admin -----

    //#region ----- Staff -----
    {
      path: '/staff',
      name: "",
      redirect: '/staff/dashboard',
      component: STAFF_COMPONENT.THE_CONTAINER,
      children: [
        //#region ----- ポータル -----
        //#region ----- ダッシュボード -----
        {
          path: 'dashboard',
          name: 'DashboardStaff',
          component: STAFF_COMPONENT.DASHBOARD,
          meta: {
            requiresAuth: true,
            roles: [ROLE.STAFF]
          }
        },
        //#endregion ----- ダッシュボード -----
        //#endregion ----- ポータル -----
        //#region ----- 宿管理 -----
        //#region ----- 宿 -----
        {
          path: 'facility',
          name: 'StaffFacility',
          redirect: 'facility/list',
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [{
            path: 'list',
            name: 'StaffFacilitySearchList',
            component: STAFF_COMPONENT.FACILITY_LIST,
            meta: {
              requiresAuth: true,
              roles: [ROLE.STAFF]

            }
          }]
        },

        //#endregion ----- 宿 -----
        //#endregion ----- 宿管理 -----
      ]
    },
    //#endregion ----- Staff -----

    //#region ----- Operator -----
    {
      path: '/operator',
      name: "",
      redirect: '/operator/dashboard',
      component: OPERATOR_COMPONENT.THE_CONTAINER,
      children: [

        //#region ----- ポータル -----
        {
          path: 'dashboard',
          name: 'DashboardOperator',
          component: OPERATOR_COMPONENT.DASHBOARD,
          meta: {
            requiresAuth: true,
            uniqueId: true,
            roles: [ROLE.ADMIN, ROLE.STAFF, ROLE.OPERATOR]
          }
        },
        //#endregion ----- ポータル -----


        //#region ----- 顧客管理 -----
        //#region 予約
        {
          path: 'reservation',
          name: 'Reservation',
          redirect: 'reservation/list',
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [{
            path: 'list',
            name: 'ReservationList',
            component: OPERATOR_COMPONENT.RESERVATION_LIST,
            meta: {
              requiresAuth: true,
              uniqueId: true,
              roles: [ROLE.ADMIN, ROLE.STAFF, ROLE.OPERATOR]
            }
          },
          {
            path: ':reservationId/edit',
            name: 'ReservationEdit',
            component: OPERATOR_COMPONENT.RESERVATION_EDIT,
            meta: {
              requiresAuth: true,
              uniqueId: true,
              roles: [ROLE.ADMIN, ROLE.STAFF, ROLE.OPERATOR]
            }
          }
          ]
        },
        //#endregion 予約

        //#region 問合せ
        {
          path: 'contact',
          name: 'Contact',
          redirect: 'contact/list',
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [{
            path: 'list',
            name: 'ContactList',
            component: OPERATOR_COMPONENT.CONTACT_LIST,
            meta: {
              requiresAuth: true,
              uniqueId: true,
              roles: [ROLE.ADMIN, ROLE.STAFF, ROLE.OPERATOR]
            }
          },
          {
            path: ':contactId/edit',
            name: 'ContactEdit',
            component: OPERATOR_COMPONENT.CONTACT_EDIT,
            meta: {
              requiresAuth: true,
              uniqueId: true,
              roles: [ROLE.ADMIN, ROLE.STAFF, ROLE.OPERATOR]
            }
          }
          ]
        },
        //#endregion 問合せ

        //#region 口コミ
        {
          path: 'review',
          name: 'Review',
          redirect: 'review/list',
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [{
            path: 'list',
            name: 'ReviewList',
            component: OPERATOR_COMPONENT.REVIEW_LIST,
            meta: {
              requiresAuth: true,
              uniqueId: true,
              roles: [ROLE.ADMIN, ROLE.STAFF, ROLE.OPERATOR]
            }
          },
          {
            path: ':reviewId/edit',
            name: 'ReviewEdit',
            component: OPERATOR_COMPONENT.REVIEW_EDIT,
            meta: {
              requiresAuth: true,
              uniqueId: true,
              roles: [ROLE.ADMIN, ROLE.STAFF, ROLE.OPERATOR]
            }
          }
          ]
        },
        //#endregion 口コミ

        //#endregion ----- 顧客管理 -----


        //#region ----- 在庫調整 ----- 
        //#region 部屋在庫調整
        {
          path: 'roomInventory/edit',
          name: 'RoomInventoryEdit',
          component: OPERATOR_COMPONENT.ROOM_INVENTORY_EDIT,
          meta: {
            requiresAuth: true,
            uniqueId: true,
            roles: [ROLE.ADMIN, ROLE.STAFF, ROLE.OPERATOR]
          }
        },
        //#endregion 部屋在庫調整
        //#region オプション在庫調整
        {
          path: 'optionInventory/edit',
          name: 'OptionInventoryEdit',
          component: OPERATOR_COMPONENT.OPTION_INVENTORY_EDIT,
          meta: {
            requiresAuth: true,
            uniqueId: true,
            roles: [ROLE.ADMIN, ROLE.STAFF, ROLE.OPERATOR]
          }
        },
        //#endregion オプション在庫調整
        //#endregion ----- 在庫調整 ----- 


        //#region ----- プラン管理 -----

        //#region プラン
        {
          path: 'plan',
          name: 'PlanOperator',
          redirect: 'plan/list',
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [{
            path: 'list',
            name: 'PlanListOperator',
            component: OPERATOR_COMPONENT.PLAN_LIST,
            meta: {
              requiresAuth: true,
              uniqueId: true,
              roles: [ROLE.ADMIN, ROLE.STAFF, ROLE.OPERATOR]
            }
          },
          {
            path: 'create',
            name: 'PlanCreateOperator',
            component: OPERATOR_COMPONENT.PLAN_CREATE,
            meta: {
              requiresAuth: true,
              uniqueId: true,
              roles: [ROLE.ADMIN, ROLE.STAFF, ROLE.OPERATOR]
            }
          },
          {
            path: ':planId/edit',
            name: 'PlanEditOperator',
            component: OPERATOR_COMPONENT.PLAN_EDIT,
            meta: {
              requiresAuth: true,
              uniqueId: true,
              roles: [ROLE.ADMIN, ROLE.STAFF, ROLE.OPERATOR]
            }
          },
          //#region プランx部屋タイプｘ掲載サイト
          {
            path: ':planId/roomType/:roomTypeId/site',
            name: 'PlanPriceOperator',
            redirect: ':planId/roomType/:roomTypeId/site/list',
            component: {
              render(c) {
                return c('router-view')
              }
            },
            children: [{
              path: 'list',
              name: 'PlanPriceSiteListOperator',
              component: OPERATOR_COMPONENT.PLAN_PRICE_LIST,
              meta: {
                requiresAuth: true,
                uniqueId: true,
                roles: [ROLE.ADMIN, ROLE.STAFF, ROLE.OPERATOR]
              }
            },
            {
              path: ':siteId/edit',
              name: 'PlanPriceEditOperator',
              component: OPERATOR_COMPONENT.PLAN_PRICE_EDIT,
              meta: {
                requiresAuth: true,
                uniqueId: true,
                roles: [ROLE.ADMIN, ROLE.STAFF, ROLE.OPERATOR]
              }
            },
            ]
          },
            //#endregion プランx部屋タイプｘ掲載サイト
          ],
        },
        //#endregion プラン

        //#region プランカテゴリ
        {
          path: 'planCategory',
          name: 'PlanCategoryOperator',
          redirect: 'planCategory/list',
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [{
            path: 'list',
            name: 'PlanCategoryListOperator',
            component: OPERATOR_COMPONENT.PLAN_CATEGORY_LIST,
            meta: {
              requiresAuth: true,
              uniqueId: true,
              roles: [ROLE.ADMIN, ROLE.STAFF, ROLE.OPERATOR]
            }
          },
          {
            path: 'create',
            name: 'PlanCategoryCreateOperator',
            component: OPERATOR_COMPONENT.PLAN_CATEGORY_CREATE,
            meta: {
              requiresAuth: true,
              uniqueId: true,
              roles: [ROLE.ADMIN, ROLE.STAFF, ROLE.OPERATOR]
            }
          },
          {
            path: ':planCategoryId/edit',
            name: 'PlanCategoryEditOperator',
            component: OPERATOR_COMPONENT.PLAN_CATEGORY_EDIT,
            meta: {
              requiresAuth: true,
              uniqueId: true,
              roles: [ROLE.ADMIN, ROLE.STAFF, ROLE.OPERATOR]
            }
          },
          ],
        },
        //#endregion プランカテゴリ

        //#region 部屋タイプ
        {
          path: 'roomType',
          name: 'RoomTypeOperator',
          redirect: 'roomType/list',
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [{
            path: 'list',
            name: 'RoomTypeListOperator',
            component: OPERATOR_COMPONENT.ROOM_TYPE_LIST,
            meta: {
              requiresAuth: true,
              uniqueId: true,
              roles: [ROLE.ADMIN, ROLE.STAFF, ROLE.OPERATOR]
            }
          },
          {
            path: 'create',
            name: 'RoomTypeCreateOperator',
            component: OPERATOR_COMPONENT.ROOM_TYPE_CREATE,
            meta: {
              requiresAuth: true,
              uniqueId: true,
              roles: [ROLE.ADMIN, ROLE.STAFF, ROLE.OPERATOR]
            }
          },
          {
            path: ':roomTypeId/edit',
            name: 'RoomTypeEditOperator',
            component: OPERATOR_COMPONENT.ROOM_TYPE_EDIT,
            meta: {
              requiresAuth: true,
              uniqueId: true,
              roles: [ROLE.ADMIN, ROLE.STAFF, ROLE.OPERATOR]
            }
          }
          ]
        },
        //#endregion 部屋タイプ        

        //#region 料金カレンダー

        {
          path: 'priceCalendar/edit',
          name: 'PriceCalendarEditOperator',
          component: OPERATOR_COMPONENT.PRICE_TYPE_CALENDAR_EDIT,
          meta: {
            requiresAuth: true,
            uniqueId: true,
            roles: [ROLE.ADMIN, ROLE.STAFF, ROLE.OPERATOR]
          }
        },
        //#endregion 料金カレンダー

        //#region キャンセル規定
        // {
        //   path: 'cancel-provision-list',
        //   name: 'CancelPolicyList',
        //   component: OPERATOR_COMPONENT.CANCEL_POLICY_LIST,
        //   meta: {
        //     requiresAuth: true,
        //     uniqueId: true,
        //     roles: [ROLE.ADMIN, ROLE.STAFF, ROLE.OPERATOR]
        //   }
        // },
        {
          path: 'cancelPolicy',
          name: 'CancelPolicyOperator',
          redirect: 'cancelPolicy/list',
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [{
            path: 'list',
            name: 'CancelPolicyListOperator',
            component: OPERATOR_COMPONENT.CANCEL_POLICY_LIST,
            meta: {
              requiresAuth: true,
              uniqueId: true,
              roles: [ROLE.ADMIN, ROLE.STAFF, ROLE.OPERATOR]
            }
          },
          {
            path: 'create',
            name: 'CancelPolicyCreateOperator',
            component: OPERATOR_COMPONENT.CANCEL_POLICY_CREATE,
            meta: {
              requiresAuth: true,
              uniqueId: true,
              roles: [ROLE.ADMIN, ROLE.STAFF, ROLE.OPERATOR]
            }
          },
          {
            path: ':cancelPolicyId/edit',
            name: 'CancelPolicyEditOperator',
            component: OPERATOR_COMPONENT.CANCEL_POLICY_EDIT,
            meta: {
              requiresAuth: true,
              uniqueId: true,
              roles: [ROLE.ADMIN, ROLE.STAFF, ROLE.OPERATOR]
            }
          },
          ]
        },

        //#endregion キャンセル規定

        //#region オプション
        {
          path: 'option',
          name: 'OptionOperator',
          redirect: 'option/list',
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [{
            path: 'list',
            name: 'OptionListOperator',
            component: OPERATOR_COMPONENT.OPTION_LIST,
            meta: {
              requiresAuth: true,
              uniqueId: true,
              roles: [ROLE.ADMIN, ROLE.STAFF, ROLE.OPERATOR]
            }
          },
          {
            path: 'create',
            name: 'OptionCreateOperator',
            component: OPERATOR_COMPONENT.OPTION_CREATE,
            meta: {
              requiresAuth: true,
              uniqueId: true,
              roles: [ROLE.ADMIN, ROLE.STAFF, ROLE.OPERATOR]
            }
          },
          {
            path: ':optionId/edit',
            name: 'OptionEditOperator',
            component: OPERATOR_COMPONENT.OPTION_EDIT,
            meta: {
              requiresAuth: true,
              uniqueId: true,
              roles: [ROLE.ADMIN, ROLE.STAFF, ROLE.OPERATOR]
            }
          },
          ]
        },
        //#endregion オプション

        //#region 質問
        {
          path: 'question',
          name: 'Question',
          redirect: 'question/list',
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [{
            path: 'list',
            name: 'QuestionList',
            component: OPERATOR_COMPONENT.QUESTION_LIST,
            meta: {
              requiresAuth: true,
              uniqueId: true,
              roles: [ROLE.ADMIN, ROLE.STAFF, ROLE.OPERATOR]
            }
          },
          {
            path: 'create',
            name: 'QuestionCreate',
            component: OPERATOR_COMPONENT.QUESTION_CREATE,
            meta: {
              requiresAuth: true,
              uniqueId: true,
              roles: [ROLE.ADMIN, ROLE.STAFF, ROLE.OPERATOR]
            }
          },
          {
            path: ':questionId/edit',
            name: 'QuestionEdit',
            component: OPERATOR_COMPONENT.QUESTION_EDIT,
            meta: {
              requiresAuth: true,
              uniqueId: true,
              roles: [ROLE.ADMIN, ROLE.STAFF, ROLE.OPERATOR]
            }
          },
          ]
        },
        //#endregion 質問

        //#region 年齢区分


        {
          path: 'age/edit',
          name: 'AgeEditOperator',
          component: OPERATOR_COMPONENT.AGE_EDIT,
          meta: {
            requiresAuth: true,
            uniqueId: true,
            roles: [ROLE.ADMIN, ROLE.STAFF, ROLE.OPERATOR]
          }
        },
        //#endregion 年齢区分

        //#endregion ----- プラン管理 -----


        //#region ----- メディア管理 -----
        // 画像
        {
          path: 'image',
          name: 'Image',
          redirect: 'image/list',
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [
            // create, editを一覧画面のModalで行っている
            {
              path: 'list',
              name: 'ImageList',
              component: OPERATOR_COMPONENT.IMAGE_LIST,
              meta: {
                requiresAuth: true,
                uniqueId: true,
                roles: [ROLE.ADMIN, ROLE.STAFF, ROLE.OPERATOR]
              }
            },
          ]
        },
        //#endregion ----- メディア管理 -----


        //#region ----- 宿管理 ------
        //#region ----- 宿編集 ------
        {
          path: 'facility/edit',
          name: 'FacilityEditOperator',
          component: OPERATOR_COMPONENT.FACILITY_EDIT,
          meta: {
            requiresAuth: true,
            uniqueId: true,
            roles: [ROLE.ADMIN, ROLE.STAFF, ROLE.OPERATOR]
          }
        },
        //#endregion ----- 宿編集 ------
        //#region ----- 宿切替 ------
        {
          path: 'facility/switchList',
          name: 'FacilitySwitch',
          component: OPERATOR_COMPONENT.FACILITY_SWITCH,
          meta: {
            requiresAuth: true,
            roles: [ROLE.OPERATOR]
          }
        },
        //#endregion ----- 宿切替 ------
        //#region ----- 宿オペレーター ------
        {
          path: 'operator',
          name: 'OperatorName',
          redirect: 'operator/list',
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [
            {
              path: 'list',
              name: 'OperatorListOperator',
              component: OPERATOR_COMPONENT.OPERATOR_LIST,
              meta: {
                requiresAuth: true,
                uniqueId: true,
                roles: [ROLE.ADMIN, ROLE.STAFF, ROLE.OPERATOR]
              }
            },
          ]
        },
        //#endregion ----- 宿オペレーター ------
        //#region ----- 宿記事 ------
        {
          path: 'facilityArticle',
          name: 'FacilityArticle',
          redirect: 'facilityArticle/list',
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [{
            path: 'list',
            name: 'FacilityArticleList',
            component: OPERATOR_COMPONENT.FACILITY_ARTICLE,
            meta: {
              requiresAuth: true,
              uniqueId: true,
              roles: [ROLE.ADMIN, ROLE.STAFF, ROLE.OPERATOR]
            }
          },]
        },
        //#endregion ----- 宿記事 ------
        //#region ----- 宿設定 ------
        {
          path: 'facility/setting',
          name: 'FacilitySettingOperator',
          component: OPERATOR_COMPONENT.FACILITY_SETTING,
          meta: {
            requiresAuth: true,
            roles: [ROLE.ADMIN]
          }
        },
        //#endregion ----- 宿設定 ------
        //#endregion ----- 宿管理 ------
      ]
    },
    //#endregion ----- Operator -----

    //#region ----- Member -----
    {
      path: '/member',
      name: '',
      redirect: '/member/dashboard',
      component: MEMBER_COMPONENT.THE_CONTAINER,
      children: [

        //#region ----- ポータル -----
        //#region ----- ダッシュボード -----
        {
          path: 'dashboard',
          name: 'DashboardMember',
          component: MEMBER_COMPONENT.DASHBOARD_DASHBOARD,
          meta: {
            requiresAuth: true,
            roles: [ROLE.ADMIN, ROLE.STAFF, ROLE.OPERATOR, ROLE.MEMBER]
          }
        },

        //#endregion ----- ダッシュボード -----
        //#endregion ----- ポータル -----
        //#region ----- 予約 -----
        //#region ----- 予約履歴 ----- 
        {
          path: 'reservation-history',
          name: 'ReservationHistory',
          redirect: 'reservation-history/list',
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [{
            path: 'list',
            name: 'ReservationHistoryList',
            component: MEMBER_COMPONENT.RESERVATIONHISTORY_LIST,
            meta: {
              requiresAuth: true,
              roles: [ROLE.ADMIN, ROLE.STAFF, ROLE.OPERATOR, ROLE.MEMBER]
            }
          }]
        },
        //#endregion ----- 予約 ----- 
        //#region ----- 空室待ち ----- 
        {
          path: 'waiting',
          name: 'Waiting',
          redirect: 'waiting/list',
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [{
            path: 'list',
            name: 'WaitingList',
            component: MEMBER_COMPONENT.WAITING_LIST,
            meta: {
              requiresAuth: true,
              roles: [ROLE.ADMIN, ROLE.STAFF, ROLE.OPERATOR, ROLE.MEMBER]
            }
          },
          {
            path: ':waitingId/edit',
            name: 'WaitingEdit',
            component: MEMBER_COMPONENT.WAITING_EDIT,
            meta: {
              requiresAuth: true,
              roles: [ROLE.ADMIN, ROLE.STAFF, ROLE.OPERATOR, ROLE.MEMBER]
            }
          }
          ]
        },
        //#endregion ----- 空室待ち ----- 
        //#endregion ----- 予約 -----

        //#region ----- クーポン ------
        {
          path: 'coupon',
          name: 'Coupon',
          redirect: 'coupon/list',
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [{
            path: 'list',
            name: 'CouponList',
            component: MEMBER_COMPONENT.COUPON_LIST,
            meta: {
              requiresAuth: true,
              roles: [ROLE.ADMIN, ROLE.STAFF, ROLE.OPERATOR, ROLE.MEMBER]
            }
          }]
        },
        //#endregion ----- クーポン ------
        //#region ----- 会員 ------
        //#region ----- 会員情報 ------
        {
          path: 'profile',
          name: 'Profile',
          redirect: 'profile/edit',
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [{
            path: 'edit',
            name: 'ProfileEdit',
            component: MEMBER_COMPONENT.PROFILE_EDIT,
            meta: {
              requiresAuth: true,
              roles: [ROLE.ADMIN, ROLE.STAFF, ROLE.OPERATOR, ROLE.MEMBER]
            }
          }]
        },
        //#endregion ----- 会員情報 ------
        //#region ----- メールマガジン ------
        {
          path: 'mail-magazine',
          name: 'EmailNewsletter',
          redirect: 'mail-magazine/list',
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [{
            path: 'list',
            // name: 'MailMagazineList.List',
            name: 'EmailList',
            component: MEMBER_COMPONENT.MAIL_MAGAZINE_LIST,
            meta: {
              requiresAuth: true,
              roles: [ROLE.ADMIN, ROLE.STAFF, ROLE.OPERATOR, ROLE.MEMBER]
            }
          }]
        },
        //#endregion ----- メールマガジン ------
        //#endregion ----- 会員 ------
      ]
    },
    //#endregion ----- Member -----

    //#region ----- Other -----
    // リダイレクト先設定 
    {
      path: '/',
      redirect: '/home',
    },
    // homeとしてのルーティング記載
    {
      path: '/home',
      name: 'Home',
      component: Home
    },
    {
      path: "/facility/:uniqueId",
      name: "FacilityDetail",
      component: FacilityDetail
    },
    {
      path: "/facility-list",
      name: "FacilityListView",
      component: FacilityList
    },
    {
      path: '/login',
      name: 'Login',
      component: Login
    },
    {
      path: '/about',
      name: 'About',
      component: About,
      meta: {
        requiresAuth: true,
        roles: [ROLE.ADMIN, ROLE.STAFF, ROLE.OPERATOR, ROLE.MEMBER]
      }
    },
    {
      path: '/logout',
      name: 'Logout',
      component: Logout
    },
    {
      path: '*',
      name: 'NotFound',
      component: NotFound
    },
    {
      path: '/test',
      name: 'TestPage',
      component: TestPage
    },
    {
      path: '/test2',
      name: 'TestPage2',
      component: TestPage2
    },
    {
      path: '/test2/:siteId',
      name: 'TestPage22',
      component: TestPage2
    },
    {
      path: '/tests',
      name: 'FacilityListTest',
      component: OPERATOR_COMPONENT.FACILITY_EDIT,
    },
    //#endregion ----- Other -----

  ]
}

//画面にuniqueIdの定義があればstoreチェック・無ければロールチェック
const checkUniqueId = ((routerParam)=>{
  return routerParam.uniqueId ? hasStoreUniqueId(routerParam.roles) : checkAccessible(routerParam.roles)
})

//uniqueIdがstoreに保持されていればtrue・無ければロールチェック
const hasStoreUniqueId = ((approvalRoles)=>{
  return store.state.uniqueId ? true : checkAccessible(approvalRoles);
})

// ルーティングのroleチェック
const checkAccessible = ((approvalRoles) => {

  const loginRoles = router.app.$keycloak.realmAccess?.roles;
  //ログイン情報無し->アクセス不可
  if (loginRoles === undefined || loginRoles === null) return false;

  const allowdView = !approvalRoles || approvalRoles.length === 0;
  return allowdView || //画面に権限指定がなければアクセス可
         loginRoles.some(loginRole => approvalRoles.includes(loginRole)); // 画面に権限指定有り・ログイン情報有り->権限一致したものはアクセス可
})

router.beforeEach((to, from, next) => {
 
  const canAccess = (router.app.$keycloak.realmAccess !== null) ? checkUniqueId(to.meta) : true; // NOTE: falseにすると動かなくなる

  // 「認証済み＆適切なロール」のみアクセス許可
  const needsAuth = to.matched.some(record => record.meta.requiresAuth);
  const authenticated = router.app.$keycloak.authenticated;
  if (!needsAuth || (authenticated && canAccess)) {
    next();
    return;
  }
  
  const fullPath = canAccess ? to.fullPath : "";
  const loginUrl = router.app.$keycloak.createLoginUrl({
    redirectUri: window.location.protocol + "//" + window.location.host + fullPath
  });
  window.location.replace(loginUrl);

})

export default router