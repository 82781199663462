export default {
  methods: {
    reqGetAreaAll(callback, errorCallback) {
      const url = `/rest/area`;
      this.baseReqGet(url, callback, errorCallback);
    },

    reqGetArea(callback, errorCallback) {
      const url = `/rest/area/${this.$route.params.areaId}`;
      this.baseReqGet(url, callback, errorCallback);
    },

    reqGetAreaList(callback, errorCallback) {
      const url = `/rest/area/arealist`;
      this.baseReqGet(url, callback, errorCallback);
    },

    reqPostArea(body, callback, errorCallback) {
      const url = `/rest/area`;
      this.baseReqPost(url, body, callback, errorCallback);
    },

    reqPutArea(body, callback, errorCallback) {
      const url = `/rest/area/${this.$route.params.areaId}`;
      this.baseReqPut(url, body, callback, errorCallback);
    },

    reqPutAreaStatus(id, body, callback, errorCallback) {
      const url = `/rest/area/${id}/status`;
      this.baseReqPut(url, body, callback, errorCallback);
    },

    reqPutAreaOrder(body, callback, errorCallback) {
      const url = `/rest/area/order`;
      this.baseReqPut(url, body, callback, errorCallback);
    },

    reqDeleteArea(callback, errorCallback) {
      const url = `/rest/area/${this.$route.params.areaId}`;
      this.baseReqDelete(url, callback, errorCallback);
    },
  },
};
