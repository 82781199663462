export default {
  methods: {
    reqGetHotelOptionAll(callback, errorCallback) {
      const url = `/rest/hotel/${this.uid}/option`;
      this.baseReqGet(url, callback, errorCallback);
    },

    reqGetHotelOption(callback, errorCallback) {
      const url = `/rest/hotel/${this.uid}/option/${this.$route.params.optionId}`;
      this.baseReqGet(url, callback, errorCallback);
    },

    reqPostHotelOption(body, callback, errorCallback) {
      const url = `/rest/hotel/${this.uid}/option`;
      this.baseReqPost(url, body, callback, errorCallback);
    },

    reqPutHotelOption(body, callback, errorCallback) {
      const url = `/rest/hotel/${this.uid}/option/${this.$route.params.optionId}`;
      this.baseReqPut(url, body, callback, errorCallback);
    },

    reqPutHotelOptionStatus(id, body, callback, errorCallback) {
      const url = `/rest/hotel/${this.uid}/option/${id}/status`;
      this.baseReqPut(url, body, callback, errorCallback);
    },

    reqPutHotelOptionOrder(body, callback, errorCallback) {
      const url = `/rest/hotel/${this.uid}/option/order`;
      this.baseReqPut(url, body, callback, errorCallback);
    },

    reqDeleteHotelOption(callback, errorCallback) {
      const url = `/rest/hotel/${this.uid}/option/${this.$route.params.optionId}`;
      this.baseReqDelete(url, callback, errorCallback);
    },
  },
};
