export default {
  methods: {
    reqGetPropertyAll(callback, errorCallback) {
      const url = `/rest/property`;
      this.baseReqGet(url, callback, errorCallback);
    },

    reqGetProperty(callback, errorCallback) {
      const url = `/rest/property/${this.$route.params.propertyId}`;
      this.baseReqGet(url, callback, errorCallback);
    },

    reqPostProperty(body, callback, errorCallback) {
      const url = `/rest/property`;
      this.baseReqPost(url, body, callback, errorCallback);
    },

    reqPutProperty(body, callback, errorCallback) {
      const url = `/rest/property/${this.$route.params.propertyId}`;
      this.baseReqPut(url, body, callback, errorCallback);
    },

    reqPutPropertyStatus(id, body, callback, errorCallback) {
      const url = `/rest/property/${id}/status`;
      this.baseReqPut(url, body, callback, errorCallback);
    },

    reqPutPropertyOrder(body, callback, errorCallback) {
      const url = `/rest/property/order`;
      this.baseReqPut(url, body, callback, errorCallback);
    },

    reqDeleteProperty(callback, errorCallback) {
      const url = `/rest/property/${this.$route.params.propertyId}`;
      this.baseReqDelete(url, callback, errorCallback);
    },
  },
};
