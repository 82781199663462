import Moment from "moment";
import config from "@/config";

export default {
  computed: {
    UTIL_STATUS() {
      return { VALID: 100, INVALID: 200 };
    },
  },

  methods: {
    //#region Common
    /**
     * デバッグログ出力
     *
     * @param {*} data
     */
    debugLog(data) {
      if (config.DEBUG_MODE) {
        console.log(JSON.stringify(data));
      }
    },

    /**
     * 数値を指定桁数の0埋め文字列にする
     *
     * @param {*} num
     * @param {*} length
     * @return {*}
     */
    zeroPadding(num, length) {
      return ("0".repeat(length) + num).slice(-length);
    },

    /**
     * YYYY-MM-DD形式で日付文字列にする
     *
     * @param {*} dateItem
     * @param {*} delimiter
     * @return {*}
     */
    getDateString(dateItem, delimiter = "-") {
      if (!dateItem) return;
      const dateString = new Moment(dateItem).format(
        `YYYY${delimiter}MM${delimiter}DD`
      );
      return dateString;
    },

    /**
     * 日数 -> 秒
     *
     * @param {*} day
     * @return {*}
     */
    dayToSeconds(day) {
      return this.hourToSeconds(day * 24);
    },

    /**
     * 時 -> 秒
     *
     * @param {*} hour
     * @return {*}
     */
    hourToSeconds(hour) {
      return this.minuteToSeconds(hour * 60);
    },

    /**
     * 分 -> 秒
     *
     * @param {*} minute
     * @return {*}
     */
    minuteToSeconds(minute) {
      return minute * 60;
    },

    /**
     * 有効無効ステータスを切り替える
     *
     * @param {*} UTIL_STATUS
     * @return {*}
     */
    toggleUTIL_STATUS(UTIL_STATUS) {
      return UTIL_STATUS == this.UTIL_STATUS.VALID
        ? this.UTIL_STATUS.INVALID
        : this.UTIL_STATUS.VALID;
    },
    //#endregion Common

    //#region vue-timepicker
    /**
     * vue-timepickerの時刻を文字列にする
     *
     * @param {*} time
     * @return {*}
     */
    timepickerString(time) {
      return !time || time.HH === undefined || time.mm === undefined
        ? time
        : `${time.HH}:${time.mm}`;
    },

    /**
     * vue-timepickerの時刻に対し「1日の終わりまで何秒か」を算出する
     *
     * @param {*} time
     * @return {*}
     */
    timepickerToSecondsBefore(time) {
      time;

      if (!time || time.HH === undefined || time.mm === undefined) return time;

      const hour = this.hourToSeconds(Number(time.HH));
      const minute = this.minuteToSeconds(Number(time.mm));

      // 1日の終わりまで何秒か
      const secondsBefore = this.dayToSeconds(1) - (hour + minute);

      return secondsBefore;
    },

    /**
     * 日数と時刻から、何秒前が締切かを取得
     *
     * @param {*} time vue-timepicker
     * @param {*} daysAgo
     * @return {*}
     */
    getDeadlineSecondsBefore(time, daysAgo = 0) {
      if (daysAgo == undefined || time == undefined) return null;

      // 日数を秒数に変換
      const daysSeconds = this.dayToSeconds(daysAgo);
      // 1日の終わりまでの秒数
      const dailyBalanceSeconds = this.timepickerToSecondsBefore(time);
      const durationSeconds = daysSeconds + dailyBalanceSeconds;
      this.debugLog(`durationSeconds: ${durationSeconds}`);
      return durationSeconds;
    },

    /**
     * vue-timepickerの時刻に対し「1日の終わりまで何秒か」を算出する
     *
     * @param {*} time
     * @return {*}
     */
    secondsToTimepickerBefore(time) {
      if (!time || time.HH === undefined || time.mm === undefined) return time;

      const hour = this.hourToSeconds(Number(time.HH));
      const minute = this.minuteToSeconds(Number(time.mm));

      // 1日の終わりまで何秒か
      const secondsBefore = this.dayToSeconds(1) - (hour + minute);

      return secondsBefore;
    },

    /**
     * 秒数から締切日を取得
     *
     * @param {Number} secondsBefore
     * @return {Number} x日前
     */
    getSecondsBeforeToDeadlineDays(secondsBefore) {
      if (secondsBefore == null || isNaN(secondsBefore)) return null;
      const days = Math.floor(secondsBefore / 86400);
      return days;
    },

    /**
     * 秒数から締切時刻を取得（hh:mm:ss）
     *
     * @param {Number} secondsBefore
     * @return {String} hh:mm:ss
     */
    getSecondsBeforeToDeadlineTime(secondsBefore) {
      if (secondsBefore == null || isNaN(secondsBefore)) return null;

      let remain = secondsBefore % 86400; // 1日未満のseconds
      remain = remain === 0 ? remain : this.dayToSeconds(1) - remain; // 「24時の何秒前 -> 0時から何秒後」の変換

      // hour:minute:seconds
      const hour = Math.floor(remain / 3600);
      remain %= 3600; // 1時間未満のseconds
      const minute = Math.floor(remain / 60);
      remain %= 60; // 1分未満のseconds
      const seconds = remain;

      const hh = this.zeroPadding(hour, 2);
      const mm = this.zeroPadding(minute, 2);
      const ss = this.zeroPadding(seconds, 2);

      const time = { HH: hh, mm: mm, ss: ss };
      this.debugLog(time);

      return time;
    },

    /**
     * 文字列をvue-timepickerオブジェクトにする
     *
     * @param {String} time
     * @return {Object} vue-timepicker
     */
    stringToTimepicker(time) {
      return time == null
        ? { HH: "00", mm: "00" }
        : { HH: time.substring(0, 2), mm: time.substring(3, 5) };
    },
    //#endregion vue-timepicker

    //#region PeriodObject
    /**
     * 期間オブジェクトを取得する
     *
     * @param {*} period
     * @return {*}
     */
    getPeriodObject(period) {
      if (
        period == null ||
        period.startDate == null ||
        period.endDate === null
      ) {
        return null;
      }

      return {
        startDate: this.getDateString(period.startDate),
        endDate: this.getDateString(period.endDate),
      };
    },
    //#endregion PeriodObject

    /** 
     * check image file 
     * */
    isImgFile(image) {
      return !(image.match(/\.(jpeg|JPEG|jpg|JPG|gif|GIF|png|PNG|svg|SVG)$/) != null);
    }
  },
};
