export default {
  methods: {
    reqGetUserHotelAll(callback, errorCallback) {
      const url = `/rest/user/${this.$route.params.userId}/hotel`;
      this.baseReqGet(url, callback, errorCallback);
    },

    reqGetUserHotel(callback, errorCallback) {
      const url = `/rest/user/${this.$route.params.userId}/hotel/${this.uid}`;
      this.baseReqGet(url, callback, errorCallback);
    },

    reqPostUserHotel(body, callback, errorCallback) {
      const url = `/rest/user/${this.$route.params.userId}/hotel`;
      this.baseReqPost(url, body, callback, errorCallback);
    },

    reqPutUserHotel(body, callback, errorCallback) {
      const url = `/rest/user/${this.$route.params.userId}/hotel/${this.uid}`;
      this.baseReqPut(url, body, callback, errorCallback);
    },

    reqPutUserHotelStatus(id, body, callback, errorCallback) {
      const url = `/rest/user/${this.$route.params.userId}/hotel/${id}/status`;
      this.baseReqPut(url, body, callback, errorCallback);
    },

    reqPutAmenityOrder(body, callback, errorCallback) {
      const url = `/rest/user/${this.$route.params.userId}/order`;
      this.baseReqPut(url, body, callback, errorCallback);
    },

    reqDeleteUserHotel(callback, errorCallback) {
      const url = `/rest/user/${this.$route.params.userId}/hotel/${this.uid}`;
      this.baseReqDelete(url, callback, errorCallback);
    },
  },
};
