export default {
  methods: {
    reqGetImageCategoryAll(callback, errorCallback) {
      const url = `/rest/imagecategory`;
      this.baseReqGet(url, callback, errorCallback);
    },

    reqGetImageCategory(callback, errorCallback) {
      const url = `/rest/imagecategory/${this.$route.params.mediaKindId}`;
      this.baseReqGet(url, callback, errorCallback);
    },

    reqPostImageCategory(body, callback, errorCallback) {
      const url = `/rest/imagecategory`;
      this.baseReqPost(url, body, callback, errorCallback);
    },

    reqPutImageCategory(body, callback, errorCallback) {
      const url = `/rest/imagecategory/${this.$route.params.mediaKindId}`;
      this.baseReqPut(url, body, callback, errorCallback);
    },

    reqPutImageCategoryStatus(id, body, callback, errorCallback) {
      const url = `/rest/imagecategory/${id}/status`;
      this.baseReqPut(url, body, callback, errorCallback);
    },

    reqPutImageCategoryOrder(body, callback, errorCallback) {
      const url = `/rest/imagecategory/order`;
      this.baseReqPut(url, body, callback, errorCallback);
    },

    reqDeleteImageCategory(callback, errorCallback) {
      const url = `/rest/imagecategory/${this.$route.params.mediaKindId}`;
      this.baseReqDelete(url, callback, errorCallback);
    },
  },
};
