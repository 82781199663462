export default {
  methods: {
    reqGetHotelAge(callback, errorCallback) {
      const url = `/rest/hotel/${this.uid}/age`;
      this.baseReqGet(url, callback, errorCallback);
    },

    reqPutHotelAge(body, callback, errorCallback) {
      const url = `/rest/hotel/${this.uid}/age`;
      this.baseReqPut(url, body, callback, errorCallback);
    },
  },
};
