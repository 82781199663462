module.exports = {
    GOOGLE_MAPS_API_KEY:"AIzaSyAmn4TyR2_1gsHf77Lo-tVJ8EBRkCzN9x8",
    LIBERTY_MEMBER_SERVER_URL: "https://profile.liberty-resort.net",
    KEYCLOAK_SERVER_AUTH_URL: "https://auth.liberty-resort.net/auth",
    IMAGE_URL: "https://picture.liberty-resort.net/files/",
    IMAGE_LG_URL: "https://picture.liberty-resort.net/files/lg/", // width: 1200px
    IMAGE_MD_URL: "https://picture.liberty-resort.net/files/md/", // width: 460px
    IMAGE_SM_URL: "https://picture.liberty-resort.net/files/sm/", // width: 180px
    ZIPCODE_URL: "https://jxamy7mdqg.execute-api.ap-northeast-1.amazonaws.com/api/",
    WPSITE: "https://yado-sagashi.com/",
    DEBUG_MODE: false,
    VERSION: 1 // 1:宿管理, 2:1+プラン管理
}

