export default {
  methods: {
    reqGetHotelTagAll(callback, errorCallback) {
      const url = `/rest/hotel/${this.uid}/tag`;
      this.baseReqGet(url, callback, errorCallback);
    },

    reqGetHotelTag(callback, errorCallback) {
      const url = `/rest/hotel/${this.uid}/tag/${this.$route.params.tagId}`;
      this.baseReqGet(url, callback, errorCallback);
    },

    reqPostHotelTag(body, callback, errorCallback) {
      const url = `/rest/hotel/${this.uid}/tag`;
      this.baseReqPost(url, body, callback, errorCallback);
    },

    reqPutHotelTag(body, callback, errorCallback) {
      const url = `/rest/hotel/${this.uid}/tag/${this.$route.params.tagId}`;
      this.baseReqPut(url, body, callback, errorCallback);
    },

    reqPutHotelTagStatus(id, body, callback, errorCallback) {
      const url = `/rest/hotel/${this.uid}/tag/${id}/status`;
      this.baseReqPut(url, body, callback, errorCallback);
    },

    reqPutHotelTagOrder(body, callback, errorCallback) {
      const url = `/rest/hotel/${this.uid}/tag/order`;
      this.baseReqPut(url, body, callback, errorCallback);
    },

    reqDeleteHotelTag(callback, errorCallback) {
      const url = `/rest/hotel/${this.uid}/tag/${this.$route.params.tagId}`;
      this.baseReqDelete(url, callback, errorCallback);
    },
  },
};
