<template>
  <div>
    <img
      :src="srcUrl"
      :class="fluid? 'w-100':''"
      @error="replaceByDefault"
    />
  </div>
</template>

<script>
export default {
  name: "LoginTimeOut",
  props: {
    src: {
      type: String,
    },
    fluid: {
      type: Boolean,
    },
  },
  data() {
    return {
    };
  },
  computed: {
    srcUrl(){
      var error = require("../assets/images/error-image.png");
      return this.src ? this.src : error;
    }
  },

  methods: {
    replaceByDefault(e) {
      e.target.src = require("../assets/images/error-image.png");
    },
  },
};
</script>
