export default {
  methods: {
    reqGetFeatureMasterAll(callback, errorCallback) {
      const url = `/rest/master/feature`;
      this.baseReqGet(url, callback, errorCallback);
    },

    reqGetFeatureMasterAllByType(featureTypeID, callback, errorCallback) {
      const url = `/rest/master/feature?type=${featureTypeID}`;
      this.baseReqGet(url, callback, errorCallback);
    },

    reqGetFeatureMaster(callback, errorCallback) {
      const url = `/rest/master/feature/${this.$route.params.featureId}`;
      this.baseReqGet(url, callback, errorCallback);
    },

    reqPostFeatureMasterByType(featureType, body, callback, errorCallback) {
      const url = `/rest/master/feature?type=${featureType}`;
      this.baseReqPost(url, body, callback, errorCallback);
    },

    reqPutFeatureMaster(body, callback, errorCallback) {
      const url = `/rest/master/feature/${this.$route.params.featureId}`;
      this.baseReqPut(url, body, callback, errorCallback);
    },

    reqPutFeatureMasterStatus(id, body, callback, errorCallback) {
      const url = `/rest/master/feature/${id}/status`;
      this.baseReqPut(url, body, callback, errorCallback);
    },

    reqPutFeatureMasterOrder(body, callback, errorCallback) {
      const url = `/rest/master/feature/order`;
      this.baseReqPut(url, body, callback, errorCallback);
    },

    reqDeleteFeatureMaster(callback, errorCallback) {
      const url = `/rest/master/feature/${this.$route.params.featureId}`;
      this.baseReqDelete(url, callback, errorCallback);
    },
  },
};
