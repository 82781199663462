export default {
  methods: {
    reqGetHotelPlanFeatureAll(callback, errorCallback) {
      const url = `/rest/hotel/${this.uid}/plan/${this.$route.params.planId}/feature`;
      this.baseReqGet(url, callback, errorCallback);
    },

    reqGetHotelPlanFeature(callback, errorCallback) {
      const url = `/rest/hotel/${this.uid}/plan/${this.$route.params.planId}/feature/${this.$route.params.featureId}`;
      this.baseReqGet(url, callback, errorCallback);
    },

    reqPostHotelPlanFeature(body, callback, errorCallback) {
      const url = `/rest/hotel/${this.uid}/plan/${this.$route.params.planId}/feature`;
      this.baseReqPost(url, body, callback, errorCallback);
    },

    reqPutHotelPlanFeature(body, callback, errorCallback) {
      const url = `/rest/hotel/${this.uid}/plan/${this.$route.params.planId}/feature/${this.$route.params.featureId}`;
      this.baseReqPut(url, body, callback, errorCallback);
    },

    reqPutHotelPlanFeatureStatus(id, body, callback, errorCallback) {
      const url = `/rest/hotel/${this.uid}/plan/${this.$route.params.planId}/feature/${id}/status`;
      this.baseReqPut(url, body, callback, errorCallback);
    },

    reqPutHotelPlanFeatureOrder(body, callback, errorCallback) {
      const url = `/rest/hotel/${this.uid}/plan/${this.$route.params.planId}/feature/order`;
      this.baseReqPut(url, body, callback, errorCallback);
    },

    reqDeleteHotelPlanFeature(callback, errorCallback) {
      const url = `/rest/hotel/${this.uid}/plan/${this.$route.params.planId}/feature/${this.$route.params.featureId}`;
      this.baseReqDelete(url, callback, errorCallback);
    },
  },
};
