export default {
  methods: {
    reqGetHotelCancelPolicyAll(callback, errorCallback) {
      const url = `/rest/hotel/${this.uid}/cancelpolicy/`;
      this.baseReqGet(url, callback, errorCallback);
    },

    reqGetHotelCancelPolicy(callback, errorCallback) {
      const url = `/rest/hotel/${this.uid}/cancelpolicy/${this.$route.params.policyId}`;
      this.baseReqGet(url, callback, errorCallback);
    },

    reqPostHotelCancelPolicy(body, callback, errorCallback) {
      const url = `/rest/hotel/${this.uid}/cancelpolicy/`;
      this.baseReqPost(url, body, callback, errorCallback);
    },

    reqPutHotelCancelPolicy(body, callback, errorCallback) {
      const url = `/rest/hotel/${this.uid}/cancelpolicy/${this.$route.params.policyId}`;
      this.baseReqPut(url, body, callback, errorCallback);
    },

    reqPutHotelCancelPolicyStatus(id, body, callback, errorCallback) {
      const url = `/rest/hotel/${this.uid}/cancelpolicy/${id}/status`;
      this.baseReqPut(url, body, callback, errorCallback);
    },

    reqPutHotelCancelPolicyOrder(body, callback, errorCallback) {
      const url = `/rest/hotel/${this.uid}/cancelpolicy/order`;
      this.baseReqPut(url, body, callback, errorCallback);
    },

    reqDeleteHotelCancelPolicy(callback, errorCallback) {
      const url = `/rest/hotel/${this.uid}/cancelpolicy/${this.$route.params.policyId}`;
      this.baseReqDelete(url, callback, errorCallback);
    },
  },
};
