<template>
  <div id="image_uploader">
    <CRow class="px-3">
      <CCol>
        <!-- <h4 class="component_title">{{ $t("imageGallery.imageAdmin") }}</h4> -->

        <div>
          <CButton
            color="info"
            class="mb-3"
            @click="addNewImgs()"
          > <i class="cil-notes"></i>
            {{ $t("imageCategoryUploadPage.addImageUpload") }}
          </CButton>
          <!-- upload image button end  -->
        </div>
        <div class="category_section">
          <ul>
            <li>
              <strong>{{ $t("imageGallery.selectCategory") }}</strong>
            </li>
            <li>
              <CSelect
                :options="CategoryList"
                :value.sync="selectedCategory"
                @change="getImgByCategory($event)"
              />
            </li>
          </ul>
        </div>
        <!-- select image caterory end  -->
        <div class="image_gallery">
          <!-- <ul
            class="image_list"
            v-if="hasImage"
          > -->
          <!-- <pre><code>{{categoryImageList}}</code></pre> -->

          <Draggable
            class="image_lists"
            v-model="categoryImageList"
            @change="onSortEnd"
            v-bind="dragOptions"
            tag="ul"
            v-if="hasImage"
          >
            <!-- <transition-group
              type="transition"
              name="flip-list"
              tag="ul"
            > -->
            <li
              v-for="image in categoryImageList"
              :key="image.id"
            ><span
                class="uploaded-image-list"
                @click="openEdit(image)"
                v-lazy:background-image="imgMd+image.fileName"
              >
                <div class="image-container">
                  <strong class="img-title">{{image.title}}</strong>
                </div>
                <!-- <img
                  :src="serverLink+image.fileName"
                  @error="replaceByDefault"
                > -->
              </span></li>
            <!-- </transition-group> -->
          </Draggable>
          <!-- </ul> -->
          <!-- show if now image  -->
          <CSpinner
            v-if="isImage"
            size="xl"
            color="info"
            class="mx-auto d-block my-5"
          />
          <div
            v-if="noImage"
            class="empty_image"
          >
            <CAlert color="warning"> {{$t('imageGallery.noImage')}} </CAlert>
          </div>

        </div>
        <!-- image list section  -->
        <!-- image edit modal start  -->
      </CCol>
    </CRow>
    <CModal
      color="info"
      size="lg"
      :closeOnBackdrop="false"
      :show.sync="editImageModal"
    >
      <template #header> {{ $t("common.imageEdit") }}

      </template>
      <div class="edit_single_img">
        <div class="submit_form">
          <CRow>
            <CCol md="3"><span class="label"><strong>{{ $t("common.category") }}</strong></span></CCol>
            <CCol>

              <CSelect
                class="w-50"
                :value.sync="uploadselectedCategory"
                :options="uploadCategoryList"
              />
            </CCol>
          </CRow>
          <CRow>
            <CCol md="3"><span class="label">
                <strong> {{ $t("imageGallery.image") }}</strong></span>
            </CCol>
            <CCol>
              <img
                class="w-100"
                :src="imgOg + selectedEditImg"
                @error="replaceByDefault"
              >
            </CCol>
          </CRow>
        </div>

        <div class="caption-section">
          <!-- <pre><code>{{selectedEditImginfo}}</code></pre> -->
          <div class="submit_form">
            <CRow>
              <CCol md="3"><span class="label">
                  <strong> {{$t('common.name')}}</strong></span>
              </CCol>
              <CCol>
                <CInput v-model="selectedEditImginfo.title" />
              </CCol>
            </CRow>
            <!-- <CRow>
              <CCol md="3">
                <span class="label">
                  <strong> {{$t('common.shortDescription')}}</strong></span>
              </CCol>
              <CCol>
                <CTextarea
                  rows="4"
                  v-model="selectedEditImginfo.description"
                />
              </CCol>
            </CRow> -->
            <CRow>
              <CCol md="3">
                <span class="label">
                  <strong> {{$t('common.showInGallery')}}</strong></span>
              </CCol>
              <CCol>
                <CSwitch
                  class="mt-1"
                  color="info"
                  data-on="On"
                  data-off="Off"
                  variant="opposite"
                  labelOn='ON'
                  labelOff="OFF"
                  :checked.sync="selectedEditImginfo.displayable"
                />
              </CCol>
            </CRow>
          </div>
        </div>
        <!-- caption section end  -->
      </div>
      <!-- edit_single_img end  -->
      <template #footer>
        <CButton
          @click="closeEditImageModal()"
          color="secondary"
        >
          <i class="icon cil-x"></i> {{ $t("common.cancel") }}
        </CButton>
        <CButton
          color="danger"
          @click="onDeleteClicked() "
        ><i class="icon cil-trash"></i>{{ $t("common.delete") }}</CButton>

        <CButton
          @click="submitEditImage()"
          color="success"
        >
          <i class="icon cil-save"></i> {{ $t("common.update") }}
        </CButton>
      </template>
    </CModal>
    <!-- image upload section starat  -->
    <CModal
      color="info"
      :closeOnBackdrop="false"
      size="lg"
      :centered="true"
      :show.sync="addNewModal"
    >
      <div class="add_img_modal">

        <div class="submit_form">
          <CRow>
            <CCol
              md="3"
              class="label"
            >
              <strong>
                {{ $t("imageGallery.selectCategory") }}
              </strong>
            </CCol>
            <CCol md="5">
              <CSelect
                class="w-75"
                :value.sync="uploadselectedCategory"
                :options="uploadCategoryList"
              />
            </CCol>
          </CRow>

          <CRow>
            <CCol
              md="3"
              class="label"
            >
              <strong>{{ $t("imageGallery.uploadImage") }}</strong>
            </CCol>
            <CCol>
              <div class="image_upload">
                <label for="files">
                  <!-- FIXME: ボタンスタイルにする -->
                  {{ $t("common.selectFiles") }}
                  <input
                    v-if="!uploadedImage"
                    type="file"
                    @change="pickFile"
                    accept="image/*"
                    class="custom-file-inputs mb-2"
                    id="files"
                    style="display:none;"
                    ref="fileInput"
                    multiple
                  />
                </label>

                <CAlert
                  color="danger"
                  class="w-75"
                  v-if="overSizeImg"
                >
                  {{ $t("imageGallery.fileLimit") }}
                </CAlert>
                <CAlert
                  color="danger"
                  class="text-center mx-auto mt-2"
                  v-if="limitExt"
                >
                  {{$t("commonMessage.maxImageUploadLimit")}}
                </CAlert>
              </div>

            </CCol>
          </CRow>
        </div>

        <CRow>
          <CCol>

            <ul class="image-upload-gallery">
              <li
                v-for="(n) in previewImages"
                :key="n"
              >
                <div
                  class="imagePreviewWrapper"
                  :style="{ 'background-image': `url(${n})` }"
                >
                </div>
              </li>
            </ul>
            <!-- {{previewImages}} -->

          </CCol>

        </CRow>
      </div>
      <template #header>
        <h4 class="modal-title">
          {{ $t("imageCategoryUploadPage.addImageUpload") }}
        </h4>
      </template>
      <template #footer>
        <CButton
          @click="close()"
          color="secondary"
        ><i class="icon cil-x"></i> {{ $t("common.cancel") }}</CButton>
        <CButton
          :disabled="!imgloaded || overSizeImg"
          @click="submitFile()"
          color="success"
        ><i class="icon cil-cloud-upload mr-1"></i> {{ $t("imageGallery.uploadImage") }}</CButton>
      </template>
    </CModal>

    <DeleteConfirmModal
      :deleteConfirmModal="deleteConfirmModal"
      :callback="deleteImg"
      @close="deleteConfirmModal = $event"
    />

    <SuccessModal
      :successModal="successModal"
      @close="successModal = $event"
    />

    <ErrorModal
      :errorModal="errorModal"
      :errorMessage="errorMessage"
      @close="errorModal = $event"
    />

  </div>
</template>

<script>
import axios from "axios";
import config from "@/config";
import SuccessModal from "@/components/SuccessModal";
import ErrorModal from "@/components/ErrorModal";
import DeleteConfirmModal from "@/components/DeleteConfirmModal";
export default {
  name: "ImageUploader",
  components: {
    SuccessModal,
    ErrorModal,
    DeleteConfirmModal,
  },
  data() {
    return {
      editImageModal: false,
      addNewModal: false,
      successModal: false,
      deleteConfirmModal: false,
      errorModal: false,
      limitExt: false,
      isDisabled: true,
      isImage: false,
      noImage: false,
      allImgs: "",
      errorMessage: "",
      preview: null,
      image: "",
      uploadedImage: "",
      isPreview: false,
      imgloaded: false,
      selectedCategory: 0,
      selectedEditCategory: 0,
      uploadselectedCategory: 1,
      allImgCategory: "",
      categoriesOpiton: [],
      CategoryList: [],
      uploadCategoryList: [],
      selectedEditImg: "",
      selectedEditImginfo: {},
      categoryImageList: [],
      test: [],
      // serverLink: "https://picture.liberty-resort.net/files/",
      imgOg: config.IMAGE_URL, // orginal size
      imgLg: config.IMAGE_LG_URL, // large size
      imgMd: config.IMAGE_MD_URL, // medium size
      imgSm: config.IMAGE_SM_URL, // small size
      textColor: "blue",
      testv: "",
      allUPloaderimg: [],
      testtt: [],
      previewImages: [],
      files: [],
    };
  },
  created() {
    this.gUniqueId = this.$store.state.uniqueId;
  },
  computed: {
    // Image file size limit
    overSizeImg() {
      return this.image.size > 2100000 ? true : false;
    },
    hasImage() {
      return this.categoryImageList.length > 0 ? true : false;
    },
    /** drag and drop */
    dragOptions() {
      return {
        animation: 0,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
      };
    },
  },
  methods: {
    /**
     * get all imgaes
     *
     */
    getAllImages() {
      this.isImage = true;
      var urlRequest = "/rest/hotel/" + this.gUniqueId + "/image";
      axios
        .get(urlRequest, {
          headers: { "Accept-Language": this.$i18n.locale },
        })
        .then((resp) => {
          // start from here  == send image as a object
          this.categoryImageList = resp.data.images;
          // Short image list by displayOrder
          this.categoryImageList.sort(
            (a, b) => a.displayOrder - b.displayOrder
          );
          this.isImage = false;
          this.categoryImageList.length <= 0
            ? (this.noImage = true)
            : (this.noImage = false);
        })
        .catch((err) => {
          console.log(err);
          this.isImage = false;
          this.noImage = true;
        });
    },
    /**
     *  Get all category list
     */
    getAllCategoryInformation: function () {
      var urlRequest = "/rest/imagecategory";
      axios
        .get(urlRequest, {
          headers: { "Accept-Language": this.$i18n.locale },
        })
        .then((resp) => {
          var allCategorys = resp.data.categories;
          this.CategoryList.push({ value: 0, label: this.$t("common.all") });
          allCategorys.map((e) =>
            this.CategoryList.push({
              value: e.id,
              label: e.name,
            })
          );
          this.CategoryList.sort((a, b) => a.id - b.id);
          allCategorys.map((e) =>
            this.uploadCategoryList.push({
              value: e.id,
              label: e.name,
            })
          );
          this.uploadCategoryList.sort((a, b) => a.id - b.id);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    /**
     * set before mounted
     *  */
    getDefImg(catID) {
      this.isImage = true;
      this.categoryImageList = [];
      var urlRequest =
        "/rest/hotel/" + this.gUniqueId + "/image/category/" + catID;
      axios
        .get(urlRequest, {
          headers: { "Accept-Language": this.$i18n.locale },
        })
        .then((resp) => {
          console.log("category image:" + JSON.stringify(resp.data));
          this.categoryImageList = resp.data.images;
          // Short image list by displayOrder
          this.categoryImageList.sort(
            (a, b) => a.displayOrder - b.displayOrder
          );
          this.isImage = false;
          this.categoryImageList.length <= 0
            ? (this.noImage = true)
            : (this.noImage = false);
        })
        .catch((err) => {
          console.log(err);
          this.categoryImageList = [];
          this.isImage = false;
          this.noImage = true;
        });
    },
    /**
     * get selected category images
     * */
    getImgByCategory(event) {
      this.testv = event.target.value;
      this.categoryImageList = "";
      event.target.value == 0
        ? this.getAllImages()
        : this.getDefImg(event.target.value);
    },
    /** button: open upload image modal */
    addNewImgs() {
      this.addNewModal = true;
      this.imgloaded = false;
    },
    /**
     * Preview Image
     *
     */
    previewImage: function (event) {
      // allUPloaderimg: [],
      // testtt: [],
      // console.log(event.target);
      var input = event.target;
      if (input.files) {
        var reader = new FileReader();
        reader.onload = (e) => {
          this.preview = e.target.result;
          this.isPreview = true;
          this.imgloaded = true;
        };
        console.log(event.target.files);
        this.image = event.target.files[0];
        reader.readAsDataURL(input.files[0]);
      }
    },
    selectImage() {
      this.$refs.fileInput.click();
    },
    pickFile(e) {
      this.allUPloaderimg = [];
      this.previewImages = [];
      var selectedFiles = e.target.files;
      console.log(e.target.files.length);
      if (e.target.files.length > 20) {
        this.isPreview = false;
        this.imgloaded = false;
        this.limitExt = true;
      } else {
        this.limitExt = false;
        let input = this.$refs.fileInput;
        let file = input.files;
        selectedFiles.forEach((element, l) => {
          // console.log(element);
          this.allUPloaderimg.push(element);
          if (file && file[l]) {
            let reader = new FileReader();
            reader.onload = (e) => {
              this.isPreview = true;
              this.imgloaded = true;
              this.previewImages.push(e.target.result);
            };
            reader.readAsDataURL(file[l]);
            this.$emit("input", file[l]);
          }
        });
        // console.log("go");
        // console.log(this.allUPloaderimg);
        this.image = e.target.files[0];
        // console.log(JSON.stringify(this.image));
        // console.log("end");
      }
    },

    /**
     * Close preview
     *  */
    close() {
      this.addNewModal = false;
      this.preview = false;
      this.image = "";
      this.previewImages = [];
      this.previewImages = [];
    },
    /**
     * Button: open dialog with image link parameter
     *
     */
    openEdit(single) {
      console.log("image Name:" + JSON.stringify(single));
      this.selectedEditImginfo = single;
      this.selectedEditImg = single.fileName;
      this.uploadselectedCategory = single.category;
      this.editImageModal = true;
    },
    /**
     * Post New Image
     *  */
    submitFile() {
      // this.allUPloaderimg.forEach((r) => {
      let formData = new FormData();
      // formData.append("files", this.allUPloaderimg);
      // formData.append("files", this.image);
      for (var i = 0; i < this.allUPloaderimg.length; i++) {
        let file = this.allUPloaderimg[i];
        formData.append("files[" + i + "]", file);
      }
      axios
        .post("https://picture.liberty-resort.net/upload", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((resp) => {
          console.log("Image response data: " + JSON.stringify(resp.data));
          this.preview = false;
          this.addNewModal = false;
          this.saveImgToFacility(resp.data);
          this.close();
          this.successModal = true;
        })
        .catch((error) => {
          this.errorMessage = error + "' error inpuload'";
          this.preview = false;
          this.errorModal = true;
          console.log(error);
          this.errorModal = true;
        });
    },
    /**
     * Put to the facility's category
     */
    saveImgToFacility(resImg) {
      var putImage = [];
      resImg.forEach((a) => {
        putImage.push({
          fileName: a,
          category: this.uploadselectedCategory,
        });
      });
      console.log("put image to category:" + JSON.stringify(putImage));
      // const urlRequest = "/rest/hotel/" + this.currentID;
      const urlRequest = "/rest/hotel/" + this.gUniqueId + "/image";
      axios
        .post(urlRequest, putImage, {
          headers: { "Accept-Language": this.$i18n.locale },
        })
        .then((resp) => {
          console.log(JSON.stringify(resp.data));
          this.addNewModal = false;
          this.successModal = true;
          this.getAllImages();
        })
        .catch((err) => {
          this.errorMessage = err + "'  post to facility '";
          this.errorModal = true;
        });
    },
    /**
     * delete single image
     */
    deleteImg() {
      this.deleteConfirmModal = false;
      const urlRequest = "/rest/hotel/images/" + this.selectedEditImg;
      axios
        .delete(urlRequest, {
          headers: { "Accept-Language": this.$i18n.locale },
        })
        .then((resp) => {
          console.log(JSON.stringify(resp.data));
          this.successModal = true;
          this.editImageModal = false;
          this.selectedCategory == 0
            ? this.getAllImages()
            : this.getDefImg(this.selectedCategory);
        })
        .catch((err) => {
          this.errorMessage = err + "'  post to facility '";
          this.errorModal = true;
        });
    },
    /**
     * update image category
     *
     */
    submitEditImage() {
      var editedImgCat = {
        category: this.uploadselectedCategory,
        title: this.selectedEditImginfo.title,
        displayable: this.selectedEditImginfo.displayable,
        // description: this.selectedEditImginfo.description,
      };
      console.log("put image to category:" + JSON.stringify(editedImgCat));
      // const urlRequest = "/rest/hotel/" + this.currentID;
      const urlRequest =
        "/rest/hotel/" + this.gUniqueId + "/image/" + this.selectedEditImg;
      axios
        .put(urlRequest, editedImgCat, {
          headers: { "Accept-Language": this.$i18n.locale },
        })
        .then((resp) => {
          console.log(JSON.stringify(resp.data));
          this.editImageModal = false;
          // this.getAllImages();
          this.selectedCategory == 0
            ? this.getAllImages()
            : this.getDefImg(this.selectedCategory);
          this.successModal = true;
        })
        .catch((err) => {
          this.errorMessage = err;
          this.errorModal = true;
        });
    },
    closeEditImageModal() {
      this.editImageModal = false;
    },
    /** onDeleteClicked */
    onDeleteClicked() {
      this.editImageModal = false;
      this.deleteConfirmModal = true;
    },
    /** image error */
    replaceByDefault(e) {
      e.target.src = require("../assets/images/error-image.png");
    },
    /** 一覧表ソート */
    onSortEnd(event) {
      // console.log(event);
      event;
      this.categoryImageList.forEach((a, i) => {
        this.categoryImageList[i].displayOrder = i;
      });
      this.reqPutDisplayOrder();
    },
    /** 表示順更新 */
    reqPutDisplayOrder() {
      let editDispalyOrder = [];
      this.categoryImageList.forEach((a) => {
        editDispalyOrder.push({
          fileName: a.fileName,
          displayOrder: a.displayOrder,
        });
      });
      console.log("submit sort data: " + JSON.stringify(editDispalyOrder));
      const urlRequest = "/rest/hotel/" + this.gUniqueId + "/image/order";
      axios
        .put(urlRequest, editDispalyOrder, {
          headers: { "Accept-Language": this.$i18n.locale },
        })
        .then((resp) => {
          console.log(JSON.stringify(resp.data));
        })
        .catch((err) => {
          this.errorMessage = err;
          this.errorModal = true;
        });
    },
  },
  /**
   * Before page Mounted
   */
  beforeMount() {
    this.getAllImages();
    // this.getDefImg(this.selectedCategory);
    this.getAllCategoryInformation();
  },
};
</script>


<style lang="scss">
.component_title {
  border-bottom: 2px solid #222222;
  display: inline-block;
  padding: 0 10px 0 0;
  margin: 0 0 25px 0;
}
.category_section {
  margin: 0 0 15px 0;
  ul {
    li {
      display: inline-block;
      padding: 0 15px 0 0;
      .form-group {
        min-width: 200px;
      }
    }
  }
}
.preview_section {
  margin: 10px auto 5px;
}
.preview_section img {
  max-height: 300px;
  width: auto;
  max-width: 100%;
}
.preview_section span {
  display: block;
}
.custom-file-inputs {
  outline: none;
  width: 100px;
}
.custom-file-inputs::-webkit-file-upload-button {
  visibility: hidden;
}
.custom-file-inputs::before {
  content: "Select files";
  color: var(--text-color);
  display: inline-block;
  background: linear-gradient(top, #f9f9f9, #e3e3e3);
  border: 1px solid #999;
  border-radius: 3px;
  padding: 5px 8px;
  outline: none;
  white-space: nowrap;
  cursor: pointer;
  text-shadow: 1px 1px #fff;
  font-weight: 700;
  font-size: 10pt;
}
.custom-file-inputs:hover::before {
  border-color: black;
}
.custom-file-inputs:active::before {
  background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
}
// gallery section start
.image_upload label {
  border: 1px solid #39f;
  color: #ffffff;
  border-radius: 4px;
  background: #39f;
  padding: 2px 8px;
  cursor: pointer;
}
.image_gallery {
  clear: both;
  .image_list {
    li {
      display: inline-block;
      width: 18%;
      margin: 1%;
      span {
        cursor: pointer;
        &:hover {
          opacity: 0.8;
        }
        img {
          width: 100%;
          border-radius: 3px;
        }
      }
    }
  }
}
.edit_single_img {
  .single_img {
    img {
      max-width: 100%;
      max-width: 500px;
    }
  }
}
.image-upload-gallery {
  li {
    display: inline-block;
    width: 25%;
    padding: 1%;
    .imagePreviewWrapper {
      width: 100%;
      padding-top: 56.25%;
      display: block;
      cursor: pointer;
      // margin: 0 auto 30px;
      background-size: cover;
      background-position: center center;
    }
  }
}
.image_lists {
  li {
    display: inline-block;
    width: 20%;
    padding: 1%;
    .uploaded-image-list {
      width: 100%;
      padding-top: 56.25%;
      display: block;
      cursor: pointer;
      position: relative;
      // margin: 0 auto 30px;
      background-size: cover;
      background-position: center center;
      transition: 0.7s ease;
      .image-container {
        // transition: 0.5s ease;
        position: absolute;
        width: 100%;
        bottom: 0;
        .img-title {
          text-align: center;
          color: #ffffff;
          font-weight: normal;
          font-size: 0.8em;
          display: block;
          box-shadow: inset 0 0 0 2000px rgba(2, 10, 39, 0.5);
        }
      }
      &:hover {
        box-shadow: inset 0 0 0 2000px rgba(26, 50, 126, 0.3);
      }
    }
  }
}
</style>