export default {
  methods: {
    reqGetBranchAll(callback, errorCallback) {
      const url = `/rest/branch`;
      this.baseReqGet(url, callback, errorCallback);
    },

    reqGetBranchAllByType(type, callback, errorCallback) {
      const url = `/rest/branch?type=${type}`;
      this.baseReqGet(url, callback, errorCallback);
    },

    reqGetBranch(callback, errorCallback) {
      const url = `/rest/branch/${this.$route.params.branchId}`;
      this.baseReqGet(url, callback, errorCallback);
    },

    reqPostBranch(body, callback, errorCallback) {
      const url = `/rest/branch`;
      this.baseReqPost(url, body, callback, errorCallback);
    },

    reqPutBranch(body, callback, errorCallback) {
      const url = `/rest/branch/${this.$route.params.branchId}`;
      this.baseReqPut(url, body, callback, errorCallback);
    },

    reqDeleteBranch(callback, errorCallback) {
      const url = `/rest/branch/${this.$route.params.branchId}`;
      this.baseReqDelete(url, callback, errorCallback);
    },
  },
};
