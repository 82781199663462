import Vue from 'vue'
import Vuex from 'vuex'
import i18n from '../i18n'
// import router from '../router'
import createPersistedState from 'vuex-persistedstate'
Vue.use(Vuex)

const state = {
  sidebarShow: 'responsive',
  sidebarMinimize: false,
  currentLanguage: 'ja',
  isLoading: false,
  editActiveTab: 0,
  planRoomTypeEditActiveTab: 0,
  editActiveCategoryNo: 29,
  uniqueId:"",
}

const mutations = {
  /**
   *  Sidebar responsive option
   * 
   */
  toggleSidebarDesktop (state) {
    const sidebarOpened = [true, 'responsive'].includes(state.sidebarShow)
    state.sidebarShow = sidebarOpened ? false : 'responsive'
  },
  toggleSidebarMobile (state) {
    const sidebarClosed = [false, 'responsive'].includes(state.sidebarShow)
    state.sidebarShow = sidebarClosed ? true : 'responsive'
  },  
/**
 * Store Switch language
 */
  clickLgEn (state) {
    state.currentLanguage = 'en'
    i18n.locale = 'en'
  },  
  clickLgJp (state) {
    state.currentLanguage = 'ja'
    i18n.locale = 'ja'


  },
  clickLgZh (state) {
    state.currentLanguage = 'zh'
    i18n.locale = 'zh'
  },
  /**
   * 
   * Store axios response result 
   */
  load (state) {
    state.isLoading= true;
  },
  notload (state) {
    state.isLoading= false;
  },
  
  /** 
   * set state
   */
  set (state, [variable, value]) {
    state[variable] = value
  },
  /**
   *set uid
   */
  setUid(state,id){
    state.uniqueId = id
  }
  
}



const actions = {
 }

const getters = { }

export default new Vuex.Store({
  state,
  mutations,
  actions,
  getters,
    // セッションに保存する
    plugins: [createPersistedState({ key: 'hotaka', storage: window.sessionStorage })],
})