export default {
  methods: {
    reqGetCurrentUserAll(callback, errorCallback) {
      const url = `/rest/currentuser`;
      this.baseReqGet(url, callback, errorCallback);
    },

    reqGetCurrentUserHotelAll(callback, errorCallback) {
      const url = `/rest/currentuser/hotels`;
      this.baseReqGet(url, callback, errorCallback);
    },
  },
};
