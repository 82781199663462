<template>
  <div class="SOut">
    

    <CModal color="danger" :closeOnBackdrop="false" :centered="true" :show.sync="GetMe">
      Error: "ログインのセッションが切れましたので、再度ログインしてください
      <template #header> セッションのタイムアウト </template>
      <template #footer>
        <CButton @click="goLogoutPage()" color="success"
          ><i class="icon cil-signin"></i> ログインへ</CButton
        >
      </template>
    </CModal>
  </div>
</template>

<script>
export default {
  name: "LoginTimeOut",
  props: {
    ErrorCode: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      errorModal: false,
    };
  },
  computed: {
    GetMe() {
      let gg = false;
      if (this.ErrorCode == 401) {
        gg = true;
      }
      return gg;
    },
  },

  methods: {
    goLogoutPage() {
      this.$router.push({ name: "Home" });
    },
  },

};
</script>

<style lang="scss">
.SOut{
 .modal{
   z-index: 999999;
 }
}

</style>
