export default {
  methods: {
    reqGetHotelPlanAll(callback, errorCallback) {
      const url = `/rest/hotel/${this.uid}/plan`;
      this.baseReqGet(url, callback, errorCallback);
    },

    reqGetHotelPlan(callback, errorCallback) {
      const url = `/rest/hotel/${this.uid}/plan/${this.$route.params.planId}`;
      this.baseReqGet(url, callback, errorCallback);
    },

    reqPostHotelPlan(body, callback, errorCallback) {
      const url = `/rest/hotel/${this.uid}/plan`;
      this.baseReqPost(url, body, callback, errorCallback);
    },

    reqPutHotelPlan(body, callback, errorCallback) {
      const url = `/rest/hotel/${this.uid}/plan/${this.$route.params.planId}`;
      this.baseReqPut(url, body, callback, errorCallback);
    },

    reqPutHotelPlanStatus(id, body, callback, errorCallback) {
      const url = `/rest/hotel/${this.uid}/plan/${id}/status`;
      this.baseReqPut(url, body, callback, errorCallback);
    },

    reqPutHotelPlanOrder(body, callback, errorCallback) {
      const url = `/rest/hotel/${this.uid}/plan/order`;
      this.baseReqPut(url, body, callback, errorCallback);
    },

    reqDeleteHotelPlan(callback, errorCallback) {
      const url = `/rest/hotel/${this.uid}/plan/${this.$route.params.planId}`;
      this.baseReqDelete(url, callback, errorCallback);
    },
  },
};
