// TODO: パラメータ合っている？

export default {
  methods: {
    reqGetHotelImageAll(callback, errorCallback) {
      const url = `/rest/hotel/${this.uid}/image/`;
      this.baseReqGet(url, callback, errorCallback);
    },

    reqGetHotelImageByFileName(fileName, callback, errorCallback) {
      const url = `/rest/hotel/${this.uid}/image/${fileName}`;
      this.baseReqGet(url, callback, errorCallback);
    },

    reqGetHotelImageByCategoryId(categoryId, callback, errorCallback) {
      const url = `/rest/hotel/${this.uid}/image/category/${categoryId}`;
      this.baseReqGet(url, callback, errorCallback);
    },

    reqPostHotelImage(body, callback, errorCallback) {
      const url = `/rest/hotel/${this.uid}/image/`;
      this.baseReqPost(url, body, callback, errorCallback);
    },

    reqPutHotelImageByFileName(fileName, body, callback, errorCallback) {
      const url = `/rest/hotel/${this.uid}/image/${fileName}`;
      this.baseReqPut(url, body, callback, errorCallback);
    },

    reqPutHotelImageOrder(body, callback, errorCallback) {
      const url = `/rest/hotel/${this.uid}/image/order`;
      this.baseReqPut(url, body, callback, errorCallback);
    },

    reqDeleteHotelImageByFileName(fileName, callback, errorCallback) {
      const url = `/rest/hotel/${this.uid}/image/${fileName}`;
      this.baseReqDelete(url, callback, errorCallback);
    },
  },
};
