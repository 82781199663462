export default {
  methods: {
    reqGetHotelUserAll(callback, errorCallback) {
      const url = `/rest/hotel/${this.uid}/user`;
      this.baseReqGet(url, callback, errorCallback);
    },

    reqGetHotelUser(callback, errorCallback) {
      const url = `/rest/hotel/${this.uid}/user/${this.$route.params.userId}`;
      this.baseReqGet(url, callback, errorCallback);
    },

    reqPostHotelUser(body, callback, errorCallback) {
      const url = `/rest/hotel/${this.uid}/user`;
      this.baseReqPost(url, body, callback, errorCallback);
    },

    reqPutHotelUser(body, callback, errorCallback) {
      const url = `/rest/hotel/${this.uid}/user/${this.$route.params.userId}`;
      this.baseReqPut(url, body, callback, errorCallback);
    },

    reqPutHotelUserStatus(id, body, callback, errorCallback) {
      const url = `/rest/hotel/${this.uid}/user/${id}/status`;
      this.baseReqPut(url, body, callback, errorCallback);
    },

    reqPutHotelUserOrder(body, callback, errorCallback) {
      const url = `/rest/hotel/${this.uid}/user/order`;
      this.baseReqPut(url, body, callback, errorCallback);
    },

    reqDeleteHotelUser(callback, errorCallback) {
      const url = `/rest/hotel/${this.uid}/user/${this.$route.params.userId}`;
      this.baseReqDelete(url, callback, errorCallback);
    },
  },
};
