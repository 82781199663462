export default {
  methods: {
    reqGetUserAll(callback, errorCallback) {
      const url = `/rest/user`;
      this.baseReqGet(url, callback, errorCallback);
    },

    reqGetUserAll2(callback, errorCallback) {
      const url = `/rest/user/`;
      this.baseReqGet(url, callback, errorCallback);
    },

    reqGetUser(callback, errorCallback) {
      const url = `/rest/user/${this.$route.params.userId}`;
      this.baseReqGet(url, callback, errorCallback);
    },

    reqPostUser(body, callback, errorCallback) {
      const url = `/rest/user`;
      this.baseReqPost(url, body, callback, errorCallback);
    },

    reqPostUserStaff(body, callback, errorCallback) {
      const url = `/rest/user/staff`;
      this.baseReqPost(url, body, callback, errorCallback);
    },

    reqPostUserStaffList(body, callback, errorCallback) {
      const url = `/rest/user/staff/list`;
      this.baseReqPost(url, body, callback, errorCallback);
    },

    reqPostUserAdmin(body, callback, errorCallback) {
      const url = `/rest/user/admin`;
      this.baseReqPost(url, body, callback, errorCallback);
    },

    reqPutUser(body, callback, errorCallback) {
      const url = `/rest/user/${this.$route.params.userId}`;
      this.baseReqPut(url, body, callback, errorCallback);
    },

    reqPutUserStatus(id, body, callback, errorCallback) {
      const url = `/rest/user/${id}/status`;
      this.baseReqPut(url, body, callback, errorCallback);
    },

    reqPutAmenityOrder(body, callback, errorCallback) {
      const url = `/rest/user/order`;
      this.baseReqPut(url, body, callback, errorCallback);
    },

    reqDeleteUser(callback, errorCallback) {
      const url = `/rest/user/${this.$route.params.userId}`;
      this.baseReqDelete(url, callback, errorCallback);
    },

    reqDeleteUserStaff(callback, errorCallback) {
      const url = `/rest/user/staff/${this.$route.params.userId}`;
      this.baseReqDelete(url, callback, errorCallback);
    },

    reqDeleteUserAdmin(callback, errorCallback) {
      const url = `/rest/user/admin/${this.$route.params.userId}`;
      this.baseReqDelete(url, callback, errorCallback);
    },
  },
};
