export default {
  methods: {
    reqGetArticleLinkAll(callback, errorCallback) {
      const url = `/rest/articleLink`;
      this.baseReqGet(url, callback, errorCallback);
    },

    reqGetArticleLink(callback, errorCallback) {
      const url = `/rest/articleLink/${this.$route.params.articleLinkId}`;
      this.baseReqGet(url, callback, errorCallback);
    },

    reqPostArticleLink(body, callback, errorCallback) {
      const url = `/rest/articleLink`;
      this.baseReqPost(url, body, callback, errorCallback);
    },

    reqPutArticleLink(body, callback, errorCallback) {
      const url = `/rest/articleLink/${this.$route.params.articleLinkId}`;
      this.baseReqPut(url, body, callback, errorCallback);
    },

    reqPutArticleLinkStatus(id, body, callback, errorCallback) {
      const url = `/rest/articleLink/${id}/status`;
      this.baseReqPut(url, body, callback, errorCallback);
    },

    reqPutArticleLinkOrder(body, callback, errorCallback) {
      const url = `/rest/articleLink/order`;
      this.baseReqPut(url, body, callback, errorCallback);
    },

    reqDeleteArticleLink(callback, errorCallback) {
      const url = `/rest/articleLink/${this.$route.params.articleLinkId}`;
      this.baseReqDelete(url, callback, errorCallback);
    },
  },
};
