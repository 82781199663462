<template>
  <div class="scroll-top">
    <transition name="fade">
      <div
        id="pagetop"
        :style="scrollB"
        v-show="scY > 300"
        @click="toTop"
      >
        <!-- <i class="cil-arrow-top"></i> -->
        <img
          src="@/assets/icons/top_btn.svg"
          width="25"
          height="25"
          alt=""
        />
      </div>

    </transition>

  </div>
</template>

<script>
export default {
  name: "ScrollTop",
  data() {
    return {
      scTimer: 0,
      scY: 0,
      // sHeight: 0,
    };
  },
  computed: {
    scrollB() {
      var BottomMargin;
      var footer = 0;
      var bottomMargin = 20;
      // document.getElementById("footer").clientHeight
      //   ? (footer = document.getElementById("footer").clientHeight)
      //   : (footer = 50);
      var fullH =
        document.documentElement.scrollHeight -
        document.documentElement.clientHeight -
        footer;
      var returnData;
      if (this.scY > fullH) {
        returnData = bottomMargin + this.scY - fullH;
      } else returnData = bottomMargin;

      BottomMargin = { bottom: returnData + "px" };
      return BottomMargin;
    },
  },

  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      if (this.scTimer) return;
      this.scTimer = setTimeout(() => {
        this.scY = window.scrollY;
        clearTimeout(this.scTimer);
        this.scTimer = 0;
      }, 0);
    },
    toTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
  },
};
</script>

<style lang="scss">
#pagetop {
  position: fixed;
  cursor: pointer;
  right: 2rem;
  background: #222222;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  text-align: center;
  right: 20px;
  bottom: 20px;
  // bottom: 3rem;
  padding: 0;
  i {
    // height: 30px;
    // width: 30px;
    margin-top: 9px;
    display: block;
    font-weight: bold;
    font-size: 1.6em;
    color: #ffffff;
  }
  img{
    width: 100%;
    height: auto;
  }
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.15s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
