export default {
  methods: {
    reqGetHotelPlanOptionAll(callback, errorCallback) {
      const url = `/rest/hotel/${this.uid}/plan/${this.$route.params.planId}/option`;
      this.baseReqGet(url, callback, errorCallback);
    },

    reqGetHotelPlanOption(callback, errorCallback) {
      const url = `/rest/hotel/${this.uid}/plan/${this.$route.params.planId}/option/${this.$route.params.optionId}`;
      this.baseReqGet(url, callback, errorCallback);
    },

    reqPostHotelPlanOption(body, callback, errorCallback) {
      const url = `/rest/hotel/${this.uid}/plan/${this.$route.params.planId}/option`;
      this.baseReqPost(url, body, callback, errorCallback);
    },

    reqPutHotelPlanOption(body, callback, errorCallback) {
      const url = `/rest/hotel/${this.uid}/plan/${this.$route.params.planId}/option/${this.$route.params.optionId}`;
      this.baseReqPut(url, body, callback, errorCallback);
    },

    reqPutHotelPlanOptionStatus(id, body, callback, errorCallback) {
      const url = `/rest/hotel/${this.uid}/plan/${this.$route.params.planId}/option/${id}/status`;
      this.baseReqPut(url, body, callback, errorCallback);
    },

    reqPutHotelPlanOptionOrder(body, callback, errorCallback) {
      const url = `/rest/hotel/${this.uid}/plan/${this.$route.params.planId}/option/order`;
      this.baseReqPut(url, body, callback, errorCallback);
    },

    reqDeleteHotelPlanOption(callback, errorCallback) {
      const url = `/rest/hotel/${this.uid}/plan/${this.$route.params.planId}/option/${this.$route.params.optionId}`;
      this.baseReqDelete(url, callback, errorCallback);
    },
  },
};
