/** 
 * API request base
 */

import axios from "axios";

export default {
  computed: {
    uid() {
      return this.$store.state.uniqueId;
    },

    axiosConfig() {
      return {
        headers: {
          Authorization: `Bearer ${this.$keycloak.token}`,
          "Accept-Language": this.$i18n.locale,
          // 'Accept-Language': `*`,
        },
      };
    },

    TYPE_FUNCTION(){
      return "function";
    }
  },

  methods: {

    //#region Base Request
    baseReqGet(url, callback, errorCallback) {
      axios
        .get(`${url}`, this.axiosConfig)
        .then((resp) => {
          this.debugLog(resp.data);
          typeof(callback) == this.TYPE_FUNCTION && callback(resp.data); // null/undefinedのcallbackは無視
        })
        .catch((err) => {
          this.debugLog(err);
          typeof(errorCallback) == this.TYPE_FUNCTION && errorCallback(String(err)); // null/undefinedのcallbackは無視
        });
    },

    baseReqPost(url, body, callback, errorCallback) {
      axios
        .post(`${url}`, body, this.axiosConfig)
        .then((resp) => {
          this.debugLog(resp.data);
          typeof(callback) == this.TYPE_FUNCTION && callback(resp.data); // null/undefinedのcallbackは無視
        })
        .catch((err) => {
          this.debugLog(err);
          typeof(errorCallback) == this.TYPE_FUNCTION && errorCallback(String(err)); // null/undefinedのcallbackは無視
        });
    },

    baseReqPut(url, body, callback, errorCallback) {
      axios
        .put(`${url}`, body, this.axiosConfig)
        .then((resp) => {
          this.debugLog(resp.data);
          typeof(callback) == this.TYPE_FUNCTION && callback(resp.data); // null/undefinedのcallbackは無視
        })
        .catch((err) => {
          this.debugLog(err);
          typeof(errorCallback) == this.TYPE_FUNCTION && errorCallback(String(err)); // null/undefinedのcallbackは無視
        });
    },

    baseReqDelete(url, callback, errorCallback) {
      axios
        .delete(`${url}`, this.axiosConfig)
        .then((resp) => {
          this.debugLog(resp.data);
          typeof(callback) == this.TYPE_FUNCTION && callback(resp.data); // null/undefinedのcallbackは無視
        })
        .catch((err) => {
          this.debugLog(err);
          typeof(errorCallback) == this.TYPE_FUNCTION && errorCallback(String(err)); // null/undefinedのcallbackは無視
        });
    },
    //#endregion Base Request

  },
};
