export default {
  methods: {
    reqGetHotelQuestionAll(callback, errorCallback) {
      const url = `/rest/hotel/${this.uid}/question`;
      this.baseReqGet(url, callback, errorCallback);
    },

    reqGetHotelQuestion(callback, errorCallback) {
      const url = `/rest/hotel/${this.uid}/question/${this.$route.params.questionId}`;
      this.baseReqGet(url, callback, errorCallback);
    },

    reqPostHotelQuestion(body, callback, errorCallback) {
      const url = `/rest/hotel/${this.uid}/question`;
      this.baseReqPost(url, body, callback, errorCallback);
    },

    reqPutHotelQuestion(body, callback, errorCallback) {
      const url = `/rest/hotel/${this.uid}/question/${this.$route.params.questionId}`;
      this.baseReqPut(url, body, callback, errorCallback);
    },

    reqPutHotelQuestionStatus(id, body, callback, errorCallback) {
      const url = `/rest/hotel/${this.uid}/question/${id}/status`;
      this.baseReqPut(url, body, callback, errorCallback);
    },

    reqPutHotelQuestionOrder(body, callback, errorCallback) {
      const url = `/rest/hotel/${this.uid}/question/order`;
      this.baseReqPut(url, body, callback, errorCallback);
    },

    reqDeleteHotelQuestion(callback, errorCallback) {
      const url = `/rest/hotel/${this.uid}/question/${this.$route.params.questionId}`;
      this.baseReqDelete(url, callback, errorCallback);
    },
  },
};
