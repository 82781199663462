import Vue from 'vue'
import i18n from '@/i18n';
import moment from 'moment'

/** 
 * Filter section
 */

// word limit filter
Vue.filter('wordLimit', function (text, length, suffix) {
  // detect japanese text and make length double
  text.match(/[\u3000-\u303f\u3040-\u309f\u30a0-\u30ff\uff00-\uff9f\u4e00-\u9faf\u3400-\u4dbf]/) ? length : (length *= 2);

  if (text.length > length) {
    return text.substring(0, length) + suffix;
  } else {
    return text;
  }
});
// upload file size 
Vue.filter('fielSize', function (size) {
  var i = Math.floor(Math.log(size) / Math.log(1024));
  return (
    (size / Math.pow(1024, i)).toFixed(2) * 1 +
    " " +
    ["B", "KB", "MB", "GB", "TB"][i]
  );
});
// date formate

// date formate filter
Vue.filter('formatDate', function (value) {
  if (value) {
    return moment(String(value)).format('YYYY年MM月DD日')
  }
});

Vue.filter('formatTime', function (value) {
  if (value) {
    return moment(String(value)).format('YYYY年MM月DD日 HH:MM')
  }
});


// Member status filter
Vue.filter('MemberStatus', function (value) {
  var statusText;
  switch (value) {
    case 99:
      statusText = "更新済み";
      break;
    case 98:
      statusText = "事前登録";
      break;
    case 1:
      statusText = "Keycloakから同期化";
      break;
    case 2:
      statusText = "WEB登録";
      break;
    case 0:
      statusText = "無効";
      break;
  }
  return statusText;

});

// Room status filter
Vue.filter('masterStatus', function (value) {
  const VALID = 100;
  return value == VALID ? true : false;
});

// Language name filter
Vue.filter('langName', function (value) {
  var AlangName;
  switch (value) {
    case "ja":
      AlangName = "日本語";
      break;
    case "en":
      AlangName = "English";
      break;
    case "zh":
      AlangName = "中文";
      break;
  }
  return AlangName;

});

// roomFloorTypeLabel filter
Vue.filter('roomFloorTypeLabel', function (value) {
  switch (value) {
    case "tatami": return `${i18n.t("common.tatami")}`;
    case "m2": return `${i18n.t("common.m2")}`;
    default: return "";
  }
});

// personBand filter
Vue.filter('personBand', function (min, max) {
  const unit = `${i18n.t("common.personUnit")}`;

  if (min === max) return `${min}${unit}`;

  // NOTE: min=3,max=5 => 3名/4名/5名 を返す
  let personBand = "";
  for (let i = min; i <= max; i++) {
    personBand += `${i}${unit}`;
    if (i < max) personBand += `/`;
  }
  return personBand;
});

// personCapacity filter
Vue.filter('personCapacity', function (min, max) {
  const unit = `${i18n.t("common.personUnit")}`;

  if (min === max) return `${min}${unit}`;
  return `${min} ~ ${max}${unit}`;
});

Vue.filter('planUseType', function (value) {
  switch (value) {
    case "0": return '宿泊';
    case "1": return '日帰り';
  }
  return "";
});

Vue.filter('planSalesType', function (value) {
  switch (value) {
    case "0": return '人数売り';
    case "1": return '部屋売り';
  }
  return "";
});

Vue.filter('planType', function (value) {
  switch (value) {
    case 1: return '宿泊・人数売り';
    case 2: return '宿泊・部屋売り';
    case 3: return '日帰り・人数売り';
    case 4: return '日帰り・部屋売り';
    default: return null;
  }
});