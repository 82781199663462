export default {
  methods: {
    reqGetArticleAll(callback, errorCallback) {
      const url = `/rest/article`;
      this.baseReqGet(url, callback, errorCallback);
    },

    reqGetArticle(callback, errorCallback) {
      const url = `/rest/article/${this.$route.params.articleId}`;
      this.baseReqGet(url, callback, errorCallback);
    },

    reqPostArticle(body, callback, errorCallback) {
      const url = `/rest/article`;
      this.baseReqPost(url, body, callback, errorCallback);
    },

    reqPutArticle(body, callback, errorCallback) {
      const url = `/rest/article/${this.$route.params.articleId}`;
      this.baseReqPut(url, body, callback, errorCallback);
    },

    reqPutArticleStatus(id, body, callback, errorCallback) {
      const url = `/rest/article/${id}/status`;
      this.baseReqPut(url, body, callback, errorCallback);
    },

    reqPutArticleOrder(body, callback, errorCallback) {
      const url = `/rest/article/order`;
      this.baseReqPut(url, body, callback, errorCallback);
    },

    reqDeleteArticle(callback, errorCallback) {
      const url = `/rest/article/${this.$route.params.articleId}`;
      this.baseReqDelete(url, callback, errorCallback);
    },
  },
};
