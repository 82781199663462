export default {
  methods: {
    reqGetRoomFeatureAll(callback, errorCallback) {
      const url = `/rest/roomfeature`;
      this.baseReqGet(url, callback, errorCallback);
    },

    reqGetRoomFeature(callback, errorCallback) {
      const url = `/rest/roomfeature/${this.$route.params.roomfeatureId}`;
      this.baseReqGet(url, callback, errorCallback);
    },

    reqPostRoomFeature(body, callback, errorCallback) {
      const url = `/rest/roomfeature`;
      this.baseReqPost(url, body, callback, errorCallback);
    },

    reqPutRoomFeature(body, callback, errorCallback) {
      const url = `/rest/roomfeature/${this.$route.params.roomfeatureId}`;
      this.baseReqPut(url, body, callback, errorCallback);
    },

    reqPutRoomFeatureStatus(id, body, callback, errorCallback) {
      const url = `/rest/roomfeature/${id}/status`;
      this.baseReqPut(url, body, callback, errorCallback);
    },

    reqPutRoomFeatureOrder(body, callback, errorCallback) {
      const url = `/rest/roomfeature/order`;
      this.baseReqPut(url, body, callback, errorCallback);
    },

    reqDeleteRoomFeature(callback, errorCallback) {
      const url = `/rest/roomfeature/${this.$route.params.roomfeatureId}`;
      this.baseReqDelete(url, callback, errorCallback);
    },
  },
};
