export default {
  methods: {
    reqGetHotelPlanCategoryRelationAll(callback, errorCallback) {
      const url = `/rest/hotel/${this.uid}/plan/${this.$route.params.planId}/category`;
      this.baseReqGet(url, callback, errorCallback);
    },

    reqPostHotelPlanCategoryRelation(body, callback, errorCallback) {
      const url = `/rest/hotel/${this.uid}/plan/${this.$route.params.planId}/category`;
      this.baseReqPost(url, body, callback, errorCallback);
    },

    reqDeleteHotelPlanCategoryRelation(callback, errorCallback) {
      const url = `/rest/hotel/${this.uid}/plan/${this.$route.params.planId}/category/${this.$route.params.categoryId}`;
      this.baseReqDelete(url, callback, errorCallback);
    },
  },
};
