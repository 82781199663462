export default {
  methods: {
    reqPostMagicHotelAge(body, callback, errorCallback) {
      const url = `/rest/magic/hotel/age`;
      this.baseReqPost(url, body, callback, errorCallback);
    },

    reqPostHotelRoomTypeFeature(body, callback, errorCallback) {
      const url = `/rest/magic/updatelocation`;
      this.baseReqPost(url, body, callback, errorCallback);
    },
  },
};
