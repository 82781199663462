import config from "@/config";
export default {
  data() {
    return {
      imgLoad: false,
      imaGUrl: config.IMAGE_URL,
    }
  },
  methods: {
    /**
     *  image load by file and size 
     * */
    imgLoadNSz(fileName, ISize) {
      if(!fileName) return;
      fileName = fileName.replace("(", "%28");
      fileName = fileName.replace(")", "%29");
      var size = "";
      var newIg = "";
      ISize ? size = ISize + '/' : size = "";
      if (fileName === undefined || fileName === null || fileName.length == 0 || fileName == " " || this.isImgFile(fileName)) {
        newIg = require("@/assets/images/no-image.png");
      } else {
        var comImg = this.imaGUrl + size + fileName;
        // var comImg = images;
        newIg = require("@/assets/images/spinner.gif");

        var img = new Image();
        img.onload = () => {
          this.imgLoad = true;
        };
        img.src = comImg;

        if (this.imgLoad) (newIg = comImg);
      }
      return newIg;
    },
    /** image loading */
    imgLoadFlt(images) {
      // console.log((images.match(/\.(jpeg|jpg|gif|png)$/) != null))
      images = images.replace("(", "%28");
      images = images.replace(")", "%29");
      var newIg = "";
      if (images === undefined || images === null || images.length == 0 || this.isImgFile(images)) {
        newIg = require("@/assets/images/no-image.png");
      } else {
        var comImg = images;
        // var comImg = images;
        newIg = require("@/assets/images/spinner.gif");

        var img = new Image();
        img.onload = () => {
          this.imgLoad = true;
        };
        // console.log(this.imgLoad);
        img.src = comImg;

        if (this.imgLoad) (newIg = comImg);
      }
      return newIg;
    },
  }
}