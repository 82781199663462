export default {
  methods: {
    reqGetHotelSiteAll(callback, errorCallback) {
      const url = `/rest/hotel/${this.uid}/site`;
      this.baseReqGet(url, callback, errorCallback);
    },

    reqGetHotelSite(callback, errorCallback) {
      const url = `/rest/hotel/${this.uid}/site/${this.$route.params.siteId}`;
      this.baseReqGet(url, callback, errorCallback);
    },

    reqPostHotelSite(body, callback, errorCallback) {
      const url = `/rest/hotel/${this.uid}/site`;
      this.baseReqPost(url, body, callback, errorCallback);
    },

    reqPutHotelSite(body, callback, errorCallback) {
      const url = `/rest/hotel/${this.uid}/site/${this.$route.params.siteId}`;
      this.baseReqPut(url, body, callback, errorCallback);
    },

    reqPutHotelSiteStatus(id, body, callback, errorCallback) {
      const url = `/rest/hotel/${this.uid}/site/${id}/status`;
      this.baseReqPut(url, body, callback, errorCallback);
    },

    reqPutHotelSiteOrder(body, callback, errorCallback) {
      const url = `/rest/hotel/${this.uid}/site/order`;
      this.baseReqPut(url, body, callback, errorCallback);
    },

    reqDeleteHotelSite(callback, errorCallback) {
      const url = `/rest/hotel/${this.uid}/site/${this.$route.params.siteId}`;
      this.baseReqDelete(url, callback, errorCallback);
    },
  },
};
