// TODO: パラメータの取り方あっている？

export default {
  methods: {
    reqGetHotelFeatureAll(callback, errorCallback) {
      const url = `/rest/hotel/${this.uid}/feature`;
      this.baseReqGet(url, callback, errorCallback);
    },

    reqPostHotelFeature(body, callback, errorCallback) {
      const url = `/rest/hotel/${this.uid}/feature`;
      this.baseReqPost(url, body, callback, errorCallback);
    },
  },
};
