import amenity from "@/mixins/request/amenity";
import area from "@/mixins/request/area";
import article from "@/mixins/request/article";
import articleLink from "@/mixins/request/articleLink";
import base from "@/mixins/request/base";
import branch from "@/mixins/request/branch";
import calendar from "@/mixins/request/calendar";
import country from "@/mixins/request/country";
import currentUser from "@/mixins/request/currentUser";
import dataRestApi from "@/mixins/request/dataRestApi";
import feature from "@/mixins/request/feature";
import featureMaster from "@/mixins/request/featureMaster";
import hotel from "@/mixins/request/hotel";
import hotelAge from "@/mixins/request/hotelAge";
import hotelAmenity from "@/mixins/request/hotelAmenity";
import hotelArticle from "@/mixins/request/hotelArticle";
import hotelCancelPolicy from "@/mixins/request/hotelCancelPolicy";
import hotelDetail from "@/mixins/request/hotelDetail";
import hotelFeature from "@/mixins/request/hotelFeature";
import hotelImage from "@/mixins/request/hotelImage";
import hotelList from "@/mixins/request/hotelList";
import hotelOption from "@/mixins/request/hotelOption";
import hotelPlan from "@/mixins/request/hotelPlan";
import hotelPlanCategory from "@/mixins/request/hotelPlanCategory";
import hotelPlanCategoryRelation from "@/mixins/request/hotelPlanCategoryRelation";
import hotelPlanFeature from "@/mixins/request/hotelPlanFeature";
import hotelPlanOption from "@/mixins/request/hotelPlanOption";
import hotelPlanProperty from "@/mixins/request/hotelPlanProperty";
import hotelPlanQuestion from "@/mixins/request/hotelPlanQuestion";
import hotelPlanRoomType from "@/mixins/request/hotelPlanRoomType";
import hotelPlanSite from "@/mixins/request/hotelPlanSite";
import hotelProperty from "@/mixins/request/hotelProperty";
import hotelQuestion from "@/mixins/request/hotelQuestion";
import hotelRanking from "@/mixins/request/hotelRanking";
import hotelRoomType from "@/mixins/request/hotelRoomType";
import hotelRoomTypeFeature from "@/mixins/request/hotelRoomTypeFeature";
import hotelRoomTypeProperty from "@/mixins/request/hotelRoomTypeProperty";
import hotelSite from "@/mixins/request/hotelSite";
import hotelTag from "@/mixins/request/hotelTag";
import hotelUser from "@/mixins/request/hotelUser";
import imageCategory from "@/mixins/request/imageCategory";
import magic from "@/mixins/request/magic";
import oauthEndPoint from "@/mixins/request/oauthEndPoint";
import plan from "@/mixins/request/plan";
import property from "@/mixins/request/property";
import roomFeature from "@/mixins/request/roomFeature";
import roomType from "@/mixins/request/roomType";
import site from "@/mixins/request/site";
import tag from "@/mixins/request/tag";
import user from "@/mixins/request/user";
import userHotel from "@/mixins/request/userHotel";
import webError from "@/mixins/request/webError";

export default {
  mixins: [
    amenity,
    area,
    article,
    articleLink,
    base,
    branch,
    calendar,
    country,
    currentUser,
    dataRestApi,
    feature,
    featureMaster,
    hotel,
    hotelAge,
    hotelAmenity,
    hotelArticle,
    hotelCancelPolicy,
    hotelDetail,
    hotelFeature,
    hotelImage,
    hotelList,
    hotelOption,
    hotelPlan,
    hotelPlanCategory,
    hotelPlanCategoryRelation,
    hotelPlanFeature,
    hotelPlanOption,
    hotelPlanProperty,
    hotelPlanQuestion,
    hotelPlanRoomType,
    hotelPlanSite,
    hotelProperty,
    hotelQuestion,
    hotelRanking,
    hotelRoomType,
    hotelRoomTypeFeature,
    hotelRoomTypeProperty,
    hotelSite,
    hotelTag,
    hotelUser,
    imageCategory,
    magic,
    oauthEndPoint,
    plan,
    property,
    roomFeature,
    roomType,
    site,
    tag,
    user,
    userHotel,
    webError,
  ],
};
