export default {
  methods: {
    reqGetFeatureAll(callback, errorCallback) {
      const url = `/rest/feature`;
      this.baseReqGet(url, callback, errorCallback);
    },

    reqGetFeature(callback, errorCallback) {
      const url = `/rest/feature/${this.$route.params.featureId}`;
      this.baseReqGet(url, callback, errorCallback);
    },

    reqPostFeature(body, callback, errorCallback) {
      const url = `/rest/feature`;
      this.baseReqPost(url, body, callback, errorCallback);
    },

    reqPutFeature(body, callback, errorCallback) {
      const url = `/rest/feature/${this.$route.params.featureId}`;
      this.baseReqPut(url, body, callback, errorCallback);
    },

    reqPutFeatureStatus(id, body, callback, errorCallback) {
      const url = `/rest/feature/${id}/status`;
      this.baseReqPut(url, body, callback, errorCallback);
    },

    reqPutFeatureOrder(body, callback, errorCallback) {
      const url = `/rest/feature/order`;
      this.baseReqPut(url, body, callback, errorCallback);
    },

    reqDeleteFeature(callback, errorCallback) {
      const url = `/rest/feature/${this.$route.params.featureId}`;
      this.baseReqDelete(url, callback, errorCallback);
    },
  },
};
