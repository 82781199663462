<template>
  <div
    id="app"
    :class="`page-${$route.name}`"
  >
    <PreLoading v-if="spinner" />
    <router-view :key="reloadRouter"></router-view>
  </div>
</template>

<script>
import i18n from "@/i18n";
export default {
  name: "App",
  metaInfo() {
    return {
      title: `${i18n.t("siteInfo.siteTitle")}`,
      meta: [
        {
          name: "description",
          content: `${i18n.t("siteInfo.siteTitle")}`,
        },
      ],
    };
  },
  data: () => ({
    isMobile: false,
  }),

  beforeDestroy() {
    if (typeof window !== "undefined") {
      window.removeEventListener("resize", this.onResize, { passive: true });
    }
  },

  mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize, { passive: true });
  },
  methods: {
    logout: function () {
      this.$store.dispatch("logout").then(() => {
        this.$router.push("/home");
      });
    },
    onResize() {
      this.isMobile = window.innerWidth < 990;
    },
  },
  computed: {
    //spinner for data response
    spinner() {
      return this.$store.state.isLoading;
    },
    //reload for language switch
    reloadRouter() {
      return this.$store.state.currentLanguage;
    },
  },
};
</script>

<style lang="scss">
@import "assets/scss/style";
</style>
