export default {
  methods: {
    reqGetSiteAll(callback, errorCallback) {
      const url = `/rest/site`;
      this.baseReqGet(url, callback, errorCallback);
    },

    reqGetSite(callback, errorCallback) {
      const url = `/rest/site/${this.$route.params.siteId}`;
      this.baseReqGet(url, callback, errorCallback);
    },

    reqPostSite(body, callback, errorCallback) {
      const url = `/rest/site`;
      this.baseReqPost(url, body, callback, errorCallback);
    },

    reqPutSite(body, callback, errorCallback) {
      const url = `/rest/site/${this.$route.params.siteId}`;
      this.baseReqPut(url, body, callback, errorCallback);
    },

    reqPutSiteStatus(id, body, callback, errorCallback) {
      const url = `/rest/site/${id}/status`;
      this.baseReqPut(url, body, callback, errorCallback);
    },

    reqPutAmenityOrder(body, callback, errorCallback) {
      const url = `/rest/site/order`;
      this.baseReqPut(url, body, callback, errorCallback);
    },

    reqDeleteSite(callback, errorCallback) {
      const url = `/rest/site/${this.$route.params.siteId}`;
      this.baseReqDelete(url, callback, errorCallback);
    },
  },
};
