export default {
  methods: {
    reqGetHotelPlanRoomTypeAll(callback, errorCallback) {
      const url = `/rest/hotel/${this.uid}/plan/${this.$route.params.planId}/room`;
      this.baseReqGet(url, callback, errorCallback);
    },

    reqGetHotelPlanRoomType(callback, errorCallback) {
      const url = `/rest/hotel/${this.uid}/plan/${this.$route.params.planId}/room/${this.$route.params.roomTypeId}`;
      this.baseReqGet(url, callback, errorCallback);
    },

    reqPostHotelPlanRoomType(body, callback, errorCallback) {
      const url = `/rest/hotel/${this.uid}/plan/${this.$route.params.planId}/room`;
      this.baseReqPost(url, body, callback, errorCallback);
    },

    reqPutHotelPlanRoomType(body, callback, errorCallback) {
      const url = `/rest/hotel/${this.uid}/plan/${this.$route.params.planId}/room/${this.$route.params.roomTypeId}`;
      this.baseReqPut(url, body, callback, errorCallback);
    },

    reqPutHotelPlanRoomTypeStatus(id, body, callback, errorCallback) {
      const url = `/rest/hotel/${this.uid}/plan/${this.$route.params.planId}/room/${id}/status`;
      this.baseReqPut(url, body, callback, errorCallback);
    },

    reqPutHotelPlanRoomTypeOrder(body, callback, errorCallback) {
      const url = `/rest/hotel/${this.uid}/plan/${this.$route.params.planId}/room/order`;
      this.baseReqPut(url, body, callback, errorCallback);
    },

    reqDeleteHotelPlanRoomType(callback, errorCallback) {
      const url = `/rest/hotel/${this.uid}/plan/${this.$route.params.planId}/room/${this.$route.params.roomTypeId}`;
      this.baseReqDelete(url, callback, errorCallback);
    },
  },
};
