export default {
  methods: {
    reqGetHotelPlanCategoryAll(callback, errorCallback) {
      const url = `/rest/hotel/${this.uid}/plancategory`;
      this.baseReqGet(url, callback, errorCallback);
    },

    reqGetHotelPlanCategory(callback, errorCallback) {
      const url = `/rest/hotel/${this.uid}/plancategory/${this.$route.params.categoryId}`;
      this.baseReqGet(url, callback, errorCallback);
    },

    reqPostHotelPlanCategory(body, callback, errorCallback) {
      const url = `/rest/hotel/${this.uid}/plancategory`;
      this.baseReqPost(url, body, callback, errorCallback);
    },

    reqPutHotelPlanCategory(body, callback, errorCallback) {
      const url = `/rest/hotel/${this.uid}/plancategory/${this.$route.params.categoryId}`;
      this.baseReqPut(url, body, callback, errorCallback);
    },

    reqPutHotelPlanCategoryStatus(id, body, callback, errorCallback) {
      const url = `/rest/hotel/${this.uid}/plancategory/${id}/status`;
      this.baseReqPut(url, body, callback, errorCallback);
    },

    reqPutHotelPlanCategoryOrder(body, callback, errorCallback) {
      const url = `/rest/hotel/${this.uid}/plancategory/order`;
      this.baseReqPut(url, body, callback, errorCallback);
    },

    reqDeleteHotelPlanCategory(callback, errorCallback) {
      const url = `/rest/hotel/${this.uid}/plancategory/${this.$route.params.categoryId}`;
      this.baseReqDelete(url, callback, errorCallback);
    },
  },
};
