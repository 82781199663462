export default {
  methods: {
    reqGetTagAll(callback, errorCallback) {
      const url = `/rest/tag`;
      this.baseReqGet(url, callback, errorCallback);
    },

    reqGetTagAllByType(type, callback, errorCallback) {
      const url = `/rest/tag?type=${type}`;
      this.baseReqGet(url, callback, errorCallback);
    },

    reqGetTag(callback, errorCallback) {
      const url = `/rest/tag/${this.$route.params.tagId}`;
      this.baseReqGet(url, callback, errorCallback);
    },

    reqPostTag(body, callback, errorCallback) {
      const url = `/rest/tag`;
      this.baseReqPost(url, body, callback, errorCallback);
    },

    reqPutTag(body, callback, errorCallback) {
      const url = `/rest/tag/${this.$route.params.tagId}`;
      this.baseReqPut(url, body, callback, errorCallback);
    },

    reqPutTagStatus(id, body, callback, errorCallback) {
      const url = `/rest/tag/${id}/status`;
      this.baseReqPut(url, body, callback, errorCallback);
    },

    reqPutAmenityOrder(body, callback, errorCallback) {
      const url = `/rest/tag/order`;
      this.baseReqPut(url, body, callback, errorCallback);
    },

    reqDeleteTag(callback, errorCallback) {
      const url = `/rest/tag/${this.$route.params.tagId}`;
      this.baseReqDelete(url, callback, errorCallback);
    },
  },
};
