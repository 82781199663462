export default {
  methods: {
    reqGetCalendarAll(callback, errorCallback) {
      const url = `/rest/calendar`;
      this.baseReqGet(url, callback, errorCallback);
    },

    reqGetCalendarByDate(date, callback, errorCallback) {
      const url = `/rest/calendar/${date}`;
      this.baseReqGet(url, callback, errorCallback);
    },

    reqPostCalendar(body, callback, errorCallback) {
      const url = `/rest/calendar`;
      this.baseReqPost(url, body, callback, errorCallback);
    },

    // TODO: これは何用？
    reqPostCalendar2(body, callback, errorCallback) {
      const url = `/rest/calendar/`;
      this.baseReqPost(url, body, callback, errorCallback);
    },

    reqPutCalendar(date, body, callback, errorCallback) {
      const url = `/rest/calendar/${date}`;
      this.baseReqPut(url, body, callback, errorCallback);
    },

    reqDeleteCalendar(date, callback, errorCallback) {
      const url = `/rest/calendar/${date}`;
      this.baseReqDelete(url, callback, errorCallback);
    },
  },
};
