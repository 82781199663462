// TODO: パラメータの取り方あっている？

export default {
  methods: {
    reqGetHotelAmenityAll(callback, errorCallback) {
      const url = `/rest/hotel/${this.uid}/amenity`;
      this.baseReqGet(url, callback, errorCallback);
    },

    reqPostHotelAmenity(body, callback, errorCallback) {
      const url = `/rest/hotel/${this.uid}/amenity`;
      this.baseReqPost(url, body, callback, errorCallback);
    },
  },
};
