<template>
  <span
    class="slide-component"
    v-if="images && images.length>0"
  >
    <!-- <CImg
      class="single-image"
      v-if="sliderImages.length<=1&& clickSlider"
      :src="ImgUrl+sliderImages[0].fileName"
    /> -->
    <div
      class="single-image"
      v-if="sliderImages.length<=1&& clickSlider"
    >
      <span
        class="single-image-box"
        v-lazy:background-image="imgLg+sliderImages[0].fileName"
      >
        <!-- <div
          class="image-container"
          v-if="sliderImages[0].title"
        >
          <strong class="img-title">{{sliderImages[0].title}}</strong>
        </div> -->
      </span>
    </div>

    <div
      class="manual-slider"
      v-else-if="clickSlider"
      ref="lImg"
    >

      <CCarousel
        arrows
        indicators
        animate
        class="art"
      >
        <CCarouselItem
          v-for="(img,i) in sliderImages"
          :key="img.fileName+i"
          :image="imgLg+img.fileName"
          class="carousel-caption-box"
          :style="{ 'height': Lheight }"
        />
        <!-- :captionHeader="img.title" -->
        <!-- :captionText="img.description" -->
      </CCarousel>
    </div>
    <div
      class="slider"
      v-else
    >

      <!-- manual slider end  -->
      <div
        class="slider_content"
        :class="spSlider ?'w-100':''"
      >
        <transition-group
          name="fade"
          tag="ul"
          class="img-sl"
        >
          <li
            v-for="i in [currentIndex]"
            :key="i"
            class="img-lt"
            v-lazy:background-image="imgLg+currentImg.fileName"
          >
            <span class="image-captions">
              <strong
                class="image-title"
                v-if="sliderImages[i].title"
              >{{sliderImages[i].title}}</strong>
              <!-- <p
                class="image-description"
                v-if="sliderImages[i].description"
              >{{sliderImages[i].description}}</p> -->
            </span>
          </li>

        </transition-group>
        <a
          class="prev"
          @click="prev"
          href="#"
        >&#10094;</a>
        <a
          class="next"
          @click="next"
          href="#"
        >&#10095;</a>
      </div>
      <div
        class="gallery_img"
        v-if="!spSlider"
      >
        <ul>
          <li
            v-for="(img, i) in sliderImages"
            :key="img.name"
            :class="i == currentIndex ? 'active' : 'inactivated'"
            @click="selectSlide(i)"
            @mouseover="selectSlideHover(i)"
            @mouseleave="selectSlideHoverOut()"
            v-lazy:background-image="imgSm+img.fileName"
          >
            <!-- <i
              class="icon cil-trash img-tr"
               v-if="isRole"
              @click="eraseImg()"
            ></i> -->
            <!-- -->

          </li>
        </ul>
      </div>
      <!-- Role:{{console.log(this)}} -->
    </div>

    <!-- slider_section-->
  </span>
</template>
<script>
// import axios from "axios";
import config from "@/config";

export default {
  name: "Slider",
  props: {
    images: {
      type: Array,
    },
    clickSlider: {
      type: Boolean,
    },
    spSlider: {
      type: Boolean,
    },
  },
  data() {
    return {
      sliderImages: [],
      timer: null,
      currentIndex: 0,
      // ImgUrl: "https://picture.liberty-resort.net/files/",
      imgOg: config.IMAGE_URL, // orginal size
      imgLg: config.IMAGE_LG_URL, // large size
      imgMd: config.IMAGE_MD_URL, // medium size
      imgSm: config.IMAGE_SM_URL, // small size
      Lheight: "",
    };
  },

  mounted: function () {
    this.startSlide();
    this.CimageHeight();
    this.CImagesHeight();
  },
  computed: {
    // isRole() {
    //   return this.$keycloak.realmAccess.roles.includes("yado_operator_admin")
    //     ? true
    //     : false;
    // },
    currentImg: function () {
      return this.sliderImages[
        Math.abs(this.currentIndex) % this.sliderImages.length
      ];
    },
  },
  methods: {
    CImagesHeight() {
      var imgWt = 0;
      var imgHt = 0;
      imgWt = this.$refs.lImg ? this.$refs.lImg.clientWidth : 0;
      imgHt = imgWt * 0.5625;
      this.Lheight = imgHt + "px";
    },
    CimageHeight() {
      // let nodeId = "vue-hgt-sf-" + this._uid;
      // let style = document.getElementById(nodeId);
      // if (!style) {
      //   style = document.createElement("style");
      //   style.id = nodeId;
      //   style.type = "text/css";
      //   this.$el.appendChild(style);
      // }
      // var imgHt = 0;
      // var imgWt = 0;
      // // this.$refs.lImg.clientWidth
      // imgWt = this.$refs.lImg ? this.$refs.lImg.clientWidth : 0;
      // imgHt = imgWt * 0.5625;
      // let cssClass = `.carousel-inner .carousel-item img { height: ${imgHt}px !important;} `;
      // style.innerHTML = cssClass;
    },
    /**
     * is only images
     *  */
    flatGallery() {
      this.sliderImages = this.images;
    },

    /**
     * slider function start
     *  */
    startSlide() {
      this.timer = setInterval(this.next, 4000);
    },
    next() {
      this.currentIndex++;
      if (this.currentIndex >= (this.sliderImages && this.sliderImages.length))
        this.currentIndex = 0;
    },
    prev() {
      this.currentIndex--;
      if (this.currentIndex < 0)
        this.currentIndex = (this.sliderImages && this.sliderImages.length) - 1;
    },
    selectSlide(i) {
      this.currentIndex = i;
    },
    selectSlideHover(i) {
      this.currentIndex = i;
      clearTimeout(this.timer);
    },
    selectSlideHoverOut() {
      this.startSlide();
    },
  },
  beforeMount() {
    this.flatGallery();
  },

  beforeUpdate() {
    this.CimageHeight();
    this.CImagesHeight();
  },
};
</script>

<style lang="scss">
.single-image {
  width: 100%;
  height: auto;
}
.single-image-box {
  width: 100%;
  padding-top: 56.25%;
  display: block;
  cursor: pointer;
  position: relative;
  background-size: cover;
  background-position: center;
  // transition: 0.7s ease;
  .image-container {
    position: absolute;
    width: 100%;
    bottom: 0;
    .img-title {
      text-align: center;
      color: #ffffff;
      font-weight: normal;
      font-size: 1em;
      padding: 0.5em 0;
      display: block;
      box-shadow: inset 0 0 0 2000px rgba(2, 10, 39, 0.5);
    }
  }
}
.slider {
  .slider_content {
    height: 100%;
    padding: 0;
    width: 58%;
    // margin: 1%;
    background: #eeeeee;
    // max-height: 60vh;
    display: inline-block;
    position: relative;
    overflow: hidden;
    .img-sl {
      width: 100%;
      padding-top: 56.25%;
      position: relative;
      overflow: hidden;
      .img-lt {
        width: 100%;
        padding-top: 56.25%;
        top: 0%;
        left: 0;
        position: absolute;
        background-size: cover;
        background-position: center;
        overflow: hidden;
        .image-captions {
          width: 100%;
          position: absolute;
          bottom: 0;
          box-shadow: inset 0 0 0 2000px rgba(2, 10, 39, 0.5);
          color: #ffffff;
          .image-title {
            font-size: 1.1em;
            line-height: 1.4em;
            padding: 0.5em 0.5em;
            display: block;
            text-align: center;
          }
          .image-description {
            margin: 0;
            padding: 1% 2% 2%;
            display: block;
            text-align: center;
          }
        }
      }
    }

    img {
      width: 100%;
      margin: 0 auto;
      display: table;
      height: auto;
    }

    .prev,
    .next {
      cursor: pointer;
      position: absolute;
      top: 40%;
      width: auto;
      padding: 16px;
      color: #cccccc;
      font-weight: bold;
      font-size: 18px;
      transition: 0.7s ease;
      border-radius: 0 4px 4px 0;
      text-decoration: none;
      user-select: none;
    }

    .next {
      right: 0;
    }

    .prev {
      left: 0;
    }

    // .prev:hover,
    // .next:hover {
    //   background-color: rgba(0, 0, 0, 0.9);
    // }
  }
  .gallery_img {
    width: 40%;
    padding: 0;
    margin: 0.5% 0 0 0;
    display: inline-block;
    vertical-align: top;
    ul {
      max-height: 92vh;
      overflow-y: auto;
    }
    li {
      &.active {
        opacity: 0.6;
      }
      position: relative;
      display: inline-block;
      width: 23%;
      padding-top: 13%;
      margin: 0.5% 1%;
      border-radius: 4px;
      overflow: hidden;
      background-size: cover;
      background-position: center;
      cursor: pointer;
      .img-tr {
        position: absolute;
        cursor: default;
        top: 5px;
        right: 5px;
        color: #f05c5c;
        background: #ffffff;
        padding: 2px 1px;
        border-radius: 4px;
      }
    }
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: all 0.5s;
  }
  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }
  .fade-enter-active {
    transition-delay: 0.5s;
  }
}
.manual-slider img {
  width: 100% !important;
  // height: 28em !important;
  object-fit: cover;
  overflow: hidden;
}
.carousel-caption-box {
  .carousel-caption {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 10;
    padding-top: 20px;
    padding-bottom: 40px;
    box-shadow: inset 0 0 0 2000px rgba(2, 10, 39, 0.5);
  }
}
</style>
