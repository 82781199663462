export default {
  methods: {
    reqGetHotelPlanQuestionAll(callback, errorCallback) {
      const url = `/rest/hotel/${this.uid}/plan/${this.$route.params.planId}/property`;
      this.baseReqGet(url, callback, errorCallback);
    },

    reqGetHotelPlanQuestion(callback, errorCallback) {
      const url = `/rest/hotel/${this.uid}/plan/${this.$route.params.planId}/property/${this.$route.params.propertyId}`;
      this.baseReqGet(url, callback, errorCallback);
    },

    reqPostHotelPlanQuestion(body, callback, errorCallback) {
      const url = `/rest/hotel/${this.uid}/plan/${this.$route.params.planId}/property`;
      this.baseReqPost(url, body, callback, errorCallback);
    },

    reqPutHotelPlanQuestion(body, callback, errorCallback) {
      const url = `/rest/hotel/${this.uid}/plan/${this.$route.params.planId}/property/${this.$route.params.propertyId}`;
      this.baseReqPut(url, body, callback, errorCallback);
    },

    reqPutHotelPlanQuestionStatus(id, body, callback, errorCallback) {
      const url = `/rest/hotel/${this.uid}/plan/${this.$route.params.planId}/property/${id}/status`;
      this.baseReqPut(url, body, callback, errorCallback);
    },

    reqPutHotelPlanQuestionOrder(body, callback, errorCallback) {
      const url = `/rest/hotel/${this.uid}/plan/${this.$route.params.planId}/property/order`;
      this.baseReqPut(url, body, callback, errorCallback);
    },

    reqDeleteHotelPlanQuestion(callback, errorCallback) {
      const url = `/rest/hotel/${this.uid}/plan/${this.$route.params.planId}/property/${this.$route.params.propertyId}`;
      this.baseReqDelete(url, callback, errorCallback);
    },
  },
};
